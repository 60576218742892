// Import the functions you need from the SDKs you need
//import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
//const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyB7GQweaB_-k_1dZRQiZb0TT-q22OtW1w0",
  authDomain: "herebedragons-e994b.firebaseapp.com",
  databaseURL: "https://herebedragons-e994b-default-rtdb.firebaseio.com",
  projectId: "herebedragons-e994b",
  storageBucket: "herebedragons-e994b.appspot.com",
  messagingSenderId: "167321316352",
  appId: "1:167321316352:web:4cda7ef6204d9170497ce5",
  measurementId: "G-LSK0HND1YV"
};

// Initialize Firebase
