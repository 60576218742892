import { FBUser } from "./FBUser";


export type UserState = {
  user?:FBUser
  isLoading:boolean
}

export const UserState0 = ():UserState => ({
  isLoading:false
})
