import { ElectricBike } from "@mui/icons-material"
import { fmap } from "../../anyonic/dsl/dsl-api"

export type EvosModel = {
  evos:{
    libNames:string[],
    libs:EvoLibContent<{num:string, name:string}>[]
  },
  audio:EvoIndexed<{num:string, audioPath:string}>

}


export type EvoDemoModel = {
  demoName:string
  libs:LibSpec[]
}

export type LibSpec = {
  libName:string
  byBpm:{
    bpm:string
    evos:EvoDef[]
    variants?:EvoElements[]
  }[]

}
  



export type EvoElements = {
  variant?:string
  evos:EvoDef[]
}

export type EvoDef = {
  lib:string  
  num:string
  audioPath:string
}


export type EvoLibContent<evo> = {
  libName:string
  sections: LibSection<evo>[]
}

export type LibSection<evo> = {
  section:string
  evos:evo[]
}

export type EvoSpec = {
  lib:string
  section:string
  num:string,
  name:string
}

export type EvoLeafModel ={
  uri:string
  numLabel:string
  audioPath:string
} & EvoSpec





export const numToName = (evos:EvoLibContent<{num:string,name:string}>[],libName:string, num:string):EvoSpec => {
  for (var lib of evos) {
    if (lib.libName === libName) {
      for (var section of lib.sections) {
        for (var evo of section.evos) {
          if (evo.num === num) {
            return {section:section.section, num, lib:libName, name:evo.name}
          }
        }
      }
    }
  }
  throw new Error(`Unknown lib:num : "${libName}":"${num}"`)
}
 


export const mapEvoModel = (evoData:EvosModel, f:(demo:string, bpm:string, lib:string, audioPath:string, evo:EvoSpec) => any):any => {
  return fmap(evoData.audio, (demo, demoName) => 
    fmap(demo as any, (libs, bpm) => 
      fmap(libs as any, (lib, libName)  => (lib as any[]).map( ({num, audioPath}) => {

        const evo:EvoSpec = numToName(evoData.evos.libs, libName, num)
        if (!evo) {
          throw new Error(`unable to resolve evo ${demoName}.${libName}.${bpm}#${num}`)
        }

        return f(demoName, bpm, libName, audioPath, evo)

      }))

    )
  )
}




export type EvoPM = {
  byURI:{[uri:string]:EvoLeafModel}
  model:{[demo:string]:{[bpm:string]:LibPM[]}}
}

export type LibPM = {
  name:string
  img:string
  sections:SectionPM[]
}

export type SectionPM = {
  name:string
  // col:string
  evos:EvoLeafModel[]
}


export type EvoResolveable = { demo:string, lib:string, bpm:string, num:string}

export const toEvoURI = ({demo, lib, bpm, num}:EvoResolveable) => `${demo}/${lib}/${bpm}/${num}` 


export type EvoIndexed<a> = {[demo:string]:{[bpm:string]:{[lib:string]:a[]}}}



export const createPM = (data:EvosModel, prefix:{AUDIO:string, IMG:string}):EvoPM => {

  var byURI:{[name:string]:EvoLeafModel} = {}
  
  // -- 1. iterte over the audio data, merging with the library information
  mapEvoModel(data, (demo, bpm, lib, audioPath, evo) => {

    const uri = toEvoURI({demo, lib, bpm, num:evo.num})

    var evoLeaf:EvoLeafModel = {
      ...evo,
      numLabel:evo.num,
      uri,
      audioPath:`${prefix.AUDIO}/${audioPath}`
    }

    byURI[uri] = evoLeaf

  })


  
  // -- 2. start by iterating over all the present demos and the bpms
  const model:{[demo:string]:{[bpm:string]:LibPM[]}}  = 
   
    fmap(data.audio as any, (demo,demoName) => 
     
      fmap(demo as any, (libsForBpm:{[lib:string]:LibSpec}, bpmName) => {
        
        

        
        var libs:LibPM[] = []

        // -- 3. and then revisit the library section data 
        data.evos.libs.forEach(({libName, sections}) => { 
          
          const libSections:SectionPM[] = sections.map(({section:name,evos }) => {
          
            const leafs:EvoLeafModel[] = evos.map(({num}) => {
              const uri = toEvoURI({demo:demoName, bpm:bpmName, lib:libName, num})
              const leaf:EvoLeafModel = byURI[uri]
              return leaf  
            }).filter(v => !!v)
            
            return {
              name,
              evos:leafs
            }
        
          })

          libs.push({
            name:libName,
            img:`${prefix.IMG}/${libName}.jpeg`,
            sections:libSections
          })
          
        }) // section iterateion
      
   


      const libs1 = libs.map(lib => ({
        ...lib, 
        sections:mapSections(lib.sections)
      }))
      
      return libs1
      
    
  
    }))
  return {byURI, model}
}


export const toFirstEvo = (pm:EvoPM, demo:string, bpm:string):EvoLeafModel => {
  const demoPM:{[bpm:string]:LibPM[]} = pm.model[demo]

  const libs:LibPM[] = demoPM[bpm]
  const lib = libs[0]
  const sec = lib.sections[0]
  const evo = sec.evos[0]
  return evo
}


   const mapSections = (sections:SectionPM[]):SectionPM[] => sections.map( (sec:SectionPM) => ({
        ...sec, 
        evos:tweakEvos(sec.evos)
      }))

      const tweakEvos = (evos:EvoLeafModel[]) => evos.reduce(
           ({prev, out}, evo) => { 
               
                if (prev && evo.num.length > prev.length && (evo.num.indexOf(prev) === 0)) {
                  const numLabel = evo.num.substring(prev.length , evo.num.length)
                  out.push({
                    ...evo,
                    numLabel
                  })
                  return {prev, out}
                } else {
                  out.push(evo)
                }
                return {prev:evo.num, out}
              }
            , {prev:null, out:[]} ).out
