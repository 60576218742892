
//import { IProcessAspect } from "./aspect/IProcessAspect";
import { $CoProxy, dsl2 } from "./dsl";
import { CompositeReducerUpdate } from "./dsl-spec"
import {  DslDef, isDef, keys, keys2o, Mu } from "./dsl-api";
import { $, $Mu, $MuReducer, ExlcudeLower } from "./mu-types";

type IProcessAspect = any

// -- some utilities to integrate the $ process dsl with react

type DslDebug = {
  update?: (update:Function) => (v:any) => void
}

type DslConfig<a, refs> = {
  pm?:a,
  _ps?:any,
  refs?:refs
  PM?:any, 
  mu?:any,
  $?:any, // $eff & {mu:$Mu<a>} => $eff
  $$?:any, 
  setStore?:any, 
  restartOn?:((pm:a) => Array<any>), 
  dbg?:DslDebug
}









/**
 * 
 * 
 * 
 */
const toPmConfig = (config:DslConfig<any, any>):any => {
    
  const {PM, pm, _ps, refs} = config
  
  var out:any = {}
  
  if (PM) {
    keys(PM, k => {
      const def = PM[k]
      out[k.replace(END0,"" )] = isDef(def) ?  def :  (Mu(def)) 
    })
  } 
  if (pm) {  // <-- mechanism to specify a single model /w namespace "pm"
    out.pm = isDef(pm) ? pm :  Mu(pm || {}) 
  }
  if (_ps) {  // <-- replace this??
    out._ps =  isDef(_ps) ? _ps :  Mu(_ps || {}) 
  }

  if (refs) {
    out._refs = Mu(refs)
  }



  return out
}

const END0 = /(_0)$/


const isPm = k => (k.indexOf('_') !== 0)

export const toPm0 = (cf:DslConfig<any, any>):any =>{ 
  if (cf.pm) {
    return _toValue0(cf.pm)
  }

  return keys2o(cf.PM, (k,o, out) => (isPm(k)) ? out[k] = _toValue0(o) : null)
}

const _toValue0 = o => isDef(o) ? o.state : o




export type PsDef = {
    state0:any,    // <-- TODO paramterize type 


    // defs (other reducer states reducers etc)
    defs?:{[ns:string]:DslDef<any>}

    // public commands
    cmds?:{[cmd:string]:(($:any) => Function)}   // public cmds, ie start = $ => 
    reduxActions?:{[action:string]:any}

} 




// -- TODO - extract. no react dependency
export const $muEff = <
    Pm   extends {[K in keyof Pm]:any} = any,
    $Eff extends {[K in keyof $Eff]: any} = any,
    Cmds extends 
      (
        {[K in keyof Partial<Pm>]:$MuReducer<Pm[K], Cmds[K]>  } 
        & { [K in keyof ExlcudeLower<Cmds, Cmds>]:(...args:any) => $Mu<Pm, Cmds> }
      ) = any,
    Refs extends {[K in keyof Partial<Refs>]:any } = any,
    CoEff$ extends {[ns:string]:{[eff:string]:Function}} = any
  >({pm, mu, refs, dbg, $, update, init, $with}:{
    pm:Pm,
    mu:Cmds,
    refs:Refs,
    update?:CompositeReducerUpdate,
    init?:CompositeReducerUpdate,
    dbg?:{aspect:IProcessAspect},
    $: ($:$<Pm, $Eff, Cmds, Refs>, $with:$CoProxy<CoEff$>) => $Eff,
    //  unfortunately, typescript doesn't seem to be powerful enough to 
    //  infer the return type of the function and inject it into 
    //  the type of the arguments   
    //  $eff: ($:$<pm, cmds, refs, $Eff>) => $Eff
    // 
    $with?:($:$<Pm, $Eff, Cmds, Refs>) => CoEff$


  }):$<Pm, $Eff,Cmds,Refs> => {
    const cfg = toPmConfig({PM:pm, mu, refs, $:$})
    const $_ = dsl2(cfg, mu, $, $with as any, pm, dbg, update)
    const pm0 = toPm0(cfg);
    init ? init(pm0) 
         : update && update(pm0);
    return $_ as any as $<Pm, $Eff, Cmds,Refs>
  }

