import { NavigateFunction } from "react-router";
import { $muEff } from "../../../anyonic/dsl";
import { CompositeReducerUpdate } from "../../../anyonic/dsl/dsl-spec";
import { $ } from "../../../anyonic/dsl/mu-types";
import { NavEff } from "./NavEff";

export type navPM = {
  location:{pathname:string, search:string}
}

export type $Nav = $<
  {
    nav:navPM
  },  
  NavEff,
  {},{}
>


const pm0 = {
  nav:{
    location:{pathname:"", search:""}
  }
}

export const createNav$ = (navigate:NavigateFunction, update?:CompositeReducerUpdate):$Nav => $muEff({
  pm:pm0,
  mu:{}, refs:{},
  $: ($:$Nav) => NavEff($, navigate),
  update
})