import { $muEff } from "../../anyonic/dsl";
import { CompositeReducerUpdate } from "../../anyonic/dsl/dsl-spec";
import { $ } from "../../anyonic/dsl/mu-types";
import { UIEff } from "./UIEff";
import { UIPm } from "./UIPm";


export type $UI = $<
  UIPm, 
  UIEff,
  {},
  {}
>


export const createUI$ = (update:CompositeReducerUpdate):$UI => $muEff({
  pm: {
    
  },
  $: ($:$UI) => ({

  }), 
  mu:{},
  refs:{},
  update,

})