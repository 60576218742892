import { FBUser } from "../../model/page/user/FBUser"

export type PresencePM = {

  user?:FBUser      // <-- me
  connected:boolean          

  // -- room definiton 
  //room:string  
  usersById:{[uid:string]:FBUser}   // <-- all users in this context/room 
  
  // -- current state
  presenceById:{[uid:string]:string} // "online" | "offline" | idlle
  userPresences:UserPresence[]         // <-- derived, more convenient for rendering
  
  

  // -- room ...
  room?:string
  channel?:string
  
}


export const presence0:PresencePM = {

    ///user?  
    room:"home",  
    usersById:{},

    presenceById:{},
    userPresences:[],

    connected:false
}
  

export type UserPresence = {
  user:FBUser
  status: UserRoomStatus 
}

export const ONLINE = "online"
export const OFFLINE = "offline"
export const IDLE = "idle"
export type UserRoomStatus = "online" | "offline" | "idle"  


export const UserPresence = (user:FBUser,   status:UserRoomStatus) =>
  ({$:'UserPM', user,status })




