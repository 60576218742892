import { EvoLeafModel } from "../model/EvosModel"
import { $Evo } from "../useEvos"



export type AudioPM = {
  pending:{[uri:string]:XMLHttpRequest}
  status:{[uri:string]:"loading"|"ok"|"failed"} // <-- undefined means load not started
  buffers:{[uri:string]:AudioBuffer}
}

export const AudioPM0 = {
  pending:{},
  status:{},
  buffers:{}
}

export type EvoAudioEff = {
  play: (evo:EvoLeafModel) => void
  setEvoVolume: (uri:string, value:number) => void
  getBuffer:(uri:string) => AudioBuffer|null
  evoStatus: (uri:string) => {ok:boolean, loading:boolean, failed:boolean}

} 


var _unpersistedVolume:{[uri:string]:number} = {

}



export const EvoAudioEff = ($:$Evo):EvoAudioEff => {
  
  // https://web.dev/webaudio-intro/

  var ctx:AudioContext = new AudioContext()


  
  const loadSound = (path, uri) => {

    const {pending, status} = $.mu.audio()

    

    const prevRequest = pending[uri]
    if (prevRequest) {


    }
    

    var request = new XMLHttpRequest();

    $.mu.audio.Append({
        pending:{[uri]:request},
        status:{[uri]:"loading"}
      })

    request.open('GET', path, true);
    request.responseType = 'arraybuffer';

    // Decode asynchronously
    request.onload = function() {



      const audioData = request.response


      ctx.decodeAudioData(audioData, buffer => {
        

      
        $.mu.audio.Append({
          status:{[uri]:"ok"},
          buffers:{[uri]:buffer}
        })

        $.player.playEvoByUri(uri)
            
      }, err => {
        console.log(` --- error ---\n   ${err.message}`)
        console.log({err})
        throw new Error("failed" + err.message)
      });

      }
      request.send();
  }

  

  return {
    evoStatus: (uri:string) =>  {
      const status = $.mu.audio().status[uri]
      return {
        ok: status === "ok",
        loading: status === "loading",
        failed: status === "failed"
      }
    },

    play: (evo:EvoLeafModel) => {
      const {uri} = evo
      const {ok, loading} =  $.audio.evoStatus(uri) 

      if (ok) {
        $.player.playEvoByUri(uri) 

      } else {
        
        $.player.setPending(uri)
      }
      
      if (!ok && !loading) {
        loadSound(evo.audioPath, evo.uri)
      }

      //}
    },

    getBuffer: uri => {
      const pm = $.mu.audio()
      const buffer = pm.buffers[uri]
      return buffer
    },

    setEvoVolume: (uri, value) => {
      const newValue = Math.max(0, Math.min(100, value))

      _unpersistedVolume[uri] = value

     // $.wave.setVolume(value)



    }

  }



}