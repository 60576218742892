import { ActionHandler, cmd, CPromise, DslDef, fmap, HandlerSpec, keys } from "./dsl-api"
import { $mu } from "./mu"
import { assert, assertFn, CompositeReducerUpdate, PROMISE, toSpec } from "./dsl-spec"
import { BATCH, batchReduce } from "./dsl-batch"
//import { IProcessAspect } from "./aspect/IProcessAspect"
import { $ } from "./mu-types"
type IProcessAspect = any

// -- create a dsl interpreter process  


export type $CoProxy<coeffs extends {[ns:string]:any}> = {
  [ns in keyof coeffs]: ((arg:{[eff:string]:Function}) => coeffs[ns]) & coeffs[ns]
} 



export const dsl2 = <pm,eff, CoEff$>(
  defs:{[ns:string]:DslDef<any>|any},  
  mu:any,  // <-- typeable.
  $fn:($:$<pm, any,any,eff>, $with:$CoProxy<any>) => eff, 
  $withFn:($:$<pm, any,any,eff>) => CoEff$|undefined,
  state0:pm,  
  dbg?:{aspect?:IProcessAspect},
  update0?:CompositeReducerUpdate, 
) => {
  
  if (mu?.$$) {
    defs = {...defs, __$$:cmd(mu.$$) }
  }

  const spec:HandlerSpec = toSpec(defs, mu, state0, update0)
  var $:any = {}

  // -- basic api  $.ps $.run  $.mu $.state
  $.run = $run(spec)
  $.state = $.mu = $mu(spec)
  $.pm = $.mu.pm
  $.ps =  fn => $.run(function*($$) {   // TODO - move this 
    const r = yield $$.ps(fn)
    return r
  })
  // -- hacky first implementation of $.refs() $.ref.property(v)
  const {_refs} = spec.byNs
  if (_refs) {
    const o = {...((_refs.def as any).state)} // TODO - more explicit ref api
    $.refs = () => o
    $.ref = fmap(o, (_,k) => (v => o[k] = v ))
  } 

  // -- coeffects

  var co$ = {}

  const registerCoEff = ns0 =>  {
    const ns = `*${ns0}`
    if (co$[ns0]) {
      throw new Error(`duplicate coalgebra, $with.${ns0} called twice`)
    }
    return (xs:{[x:string]:Function}) => {
      const $ns = $[ns] = co$[ns0] || (co$[ns0] = {})    
      keys(xs, (k, eff) => $ns[k] = dbg?.aspect ? dbg?.aspect.decorate(ns, k, eff) : eff)
      return $ns
    }
  }

  // -- 1. read extracted coeffects from $with:$ =>  { myCoEff: ({ ... })}
  $withFn && keys($withFn($), (ns, coeff) => registerCoEff(ns)(coeff) )

  // -- 2. inline effect $: $ => ({ ...   MyEffImpl($, $with.op)})
  const $with = new Proxy(co$, {
    get:  (_, key, __) => co$[key.toString()] || registerCoEff(key.toString()) 
  })
  
  // -- 3. recurse on effects
  inject$2($,$fn($, $with), '', dbg?.aspect )

  return $
}


const inject$2 = ($, eff0, ns:string, aspect?:IProcessAspect) => keys(eff0, (k, eff) => { 



    if (RESERVED[k]) {
      assert(false, `TEMPORARY ERROR: "${k}" is a reserved ps term, and  so an invalid effect name. (only applies to top level)`)
    }
    if (k.indexOf('$') === 0) {
      $[k] = eff
    } else if (isFn(eff)) {  // <-- 1. leaf effect, ie 'start: () => {...}
      $[k] = aspect ? aspect.decorate(ns, k, eff) : eff
    } else {          // <-- 2. recurse on child object, ie 'eff1: { CmdX: x => ... }`
      $[k] = {}
      const ns1 = (ns.length > 0 ? `${ns}.` : '') + k
      inject$2($[k], eff, ns1, aspect) 
    }
  })





const RESERVED =  {mu:true, state:true, ps:true, run:true  }




const $run = (spec:HandlerSpec):(gen:any) => CPromise<any> =>  {
  const {$$, handlers} = spec

  const _$run = gen => {
    var _canceled = false
    var inProgress:Promise<any>|null // <-- in progress cancellable  promise
    
    const cancel = () => {
       _canceled = true
       if (inProgress instanceof CPromise) {
         inProgress.cancel()   
       }
    }

    var it = gen($$) 
    var value
    var done = false

    const out = new CPromise(resolve  => {

      const tick = v => {
        while (!done && !_canceled) {
          ({ value, done } = it.next(v)); // <-- generator supplies  f: (a -> t b)
          v = null // <-- synchronous selector may set the call value below
          var handler:ActionHandler|null = null
          if (done) {
            resolve(value)
            return
          } else {
            if (value == null) {
              assert(false, "--- 'yield null' <-- probably $$.Misspelling")
            }
            var name
            try {
              name = value ? value.$ : null
            } catch (e) {
              throw new Error('invalid yield $$')
            }

            
            if (name === BATCH) {
              v = batchReduce(value, spec)
            } else {

              if (name === PROMISE) {
                name = value.name 
              } 
             
              handler = handlers[name] 
      
              if (!handler) {
                assert(false, `return only commands from the $$ obeject `, {value})
              } else {

                const {fn, def, ref} = handler 
                assertFn(fn)
                //console.log(`ACTION: ${handler!.name}  yield `, {value})
                switch (def.$) {
                  case 'Dispatch':
                    if (handler.isSelector) { 
                      v = fn(def.selectors!.stateRef!.current) 
                      throw new Error("TODO - Dispatch selector is untested")
                    } else {
                      def.dispatch(value.v) 
                    } 
                    break
                  
                  case 'Reducer':
                    if (handler.isSelector) {
                  
                      v = fn(ref.current) // <-- this will be the return value as we synchronously continue loop 
                      //console.log(`   v =  `, {v})
                    } else {  
                      var state = fn(ref.current, value.v)
                      if (state === null || state === undefined) {
                        throw new Error(`null from reducer ${handler.name}`)
                      }
                      if (state !== ref.current) {  // <-- don't mutate values, will fail to perform update on view 
                        //console.log(`   -> state = `, {state})
                        
                        //ref.current = state     // <-- mutating values (here's where we implement the state effect
                        //def.setState(state)   // <-- this is the react effect
                        spec.update({[handler.ns]:state})
                      }
                      v = ref.current  // <-- return the current reducer value 
                  }
                    break
                  case 'Async':
                  if (def.isGen) {
                    gen = fn(value)
                    inProgress = _$run(gen)  // <-- yield $$.await(function*($$) ...)
                  } else {
                    inProgress = fn(value)  // <--  yield $$.await( () => Promise ) 
                  }
                  inProgress!
                      .then(v => {  //
                        //console.log(`   =>  v = `, {v});
                        inProgress = null
                        if (!_canceled) {
                          tick(v)
                        }
                      })
                      .catch(err => {
                        inProgress = null
                          const stack = err.stack
                        console.log(` error: ${err.message} \n ${stack}`)
                        throw new Error(`Command failed ${handler!.name}   message: \n + ${err.message}` )
                      })
                      
                    if (!(inProgress instanceof Promise)) {
                      throw new Error('function must return promise')
                    }
                    return  // <-- stop iteration, as we've injected the control flow into the promise
                }
              }
            }
          }
        }
        } // tick
  
      tick(null)  // <-- start

    })  
    out.cancel = cancel // <-- more "stop" that cancel, really
    return out
  }
  return _$run
}

// -- core language includes
//   $.wait(time) 
//   $.await(()=> toPromise())  // <-- must be a function returing a promise, not a promise

//   -- process needs some thought, moving towrds FRP
//
//     const token = yield $$.ps(( ok, err, ps, $) =>  {
//      // some async thing:
//         ps.signal(v)   <-- publish to proces
//      return {
//         cancel:() =>       
//         '|done>':       
//       }
//     
//     while (!done)
//


// -- extract error handling


const isFn = o => (typeof o == "function")