import React, {  useEffect, useMemo } from "react";
import { NavigateFunction, useLocation } from "react-router";
import { createStore, useStore } from "./anyonic/dsl/createStorePs$";
import { MediaEnv } from "./model/page/resource/MediaEnv";
import { Page, shouldLoad } from "./model/page/resource/PageState";
import { $Page, createPage$ } from "./model/ps/page/$Page";
import { $User, createUser$ } from "./ps/auth/$User";
import { PresencePM } from "./ps/firebase-ps/PresencePM";
import { $Presence, createPresence$ } from "./ps/firebase-ps/$Presence";

//import { UIState } from "./ui/model/UIState";

import { useNavigate } from "react-router-dom";
import { FBUser } from "./model/page/user/FBUser";
import { NavEff } from "./model/ps/nav/NavEff";
import { createNav$ } from "./model/ps/nav/$Nav";
import { CompositeReducerUpdate } from "./anyonic/dsl/dsl-spec";
import { UserState } from "./model/page/user/UserState";


export const MediaContext = React.createContext(null as any);

export type MediaPsPM<$ui> = {
  
  $page:$Page
  $user:$User
  $presence:$Presence
  $ui:$ui

} // -- mediaPs




const store = { 
  user:createStore(null),
  page:createStore(null),
  media:createStore(null),
  presence:createStore(null),
  ui:createStore(null)
  // media:
}


var $:MediaPsPM<any>

export type PsFactory<$> = (update:CompositeReducerUpdate) => $


const create$= <$ui,>(
  env:MediaEnv<any>, 
  createUI$: PsFactory<$ui>,
  navigate:NavigateFunction
):MediaPsPM<$ui> => {

  const $nav:NavEff = createNav$(navigate) 

  const $page = createPage$(env, $nav, v => {
    store.page.setState(v)
  } )  // <-- note that this invokes rendering at "/""


  
  return {
    $user: createUser$(store.user.setState, $nav ),
    $page,
    $presence: createPresence$(store.presence.setState),
    $ui: createUI$(store.ui.setState) // createAppUIPs(store.ui),
    //media: useMedia$
  }
}



// -- temporary global mechanism to collect app proceses


/**
 * 
 * General context provider  
 *  
 *  
 */
export const MediaContextProvider = <$ui, uiPM, media>({ 
    env, 
    ps$,
    children
  }: {
    env:MediaEnv<media>
    ps$: {
      createUI$:PsFactory<$ui> 
    },
    children?:any
  }) => {
    
  const location = useLocation()
  const navigate = useNavigate()  

  
  // 1. create the store (state effect)
  useMemo( () => {
      // FIX_THIS - still using global variable
      return $ = $ || create$(env, ps$.createUI$, navigate  )    
    }, [env, ps$.createUI$, navigate])
        
  // 2. start the user process
  useEffect(() => {
    $.$user.start()
  }, [])

  const user:UserState = useUser()


  // 3.  presence process inherently depends on the use state
  useEffect(() => {
    console.log('x')
    $.$presence.pr.start(user?.user)
  }, [user?.user] )


  // 4. navigation effects 




  useEffect(() => {
    $.$page.page.setLocation(location)
  }, [location, navigate]) 


  // 5.  page loading effects 

  const page = usePage()

  useEffect(() => {
    if (shouldLoad(page)) {
      $.$page.pg.load(page)  // <-- probably now can remove all of this
    }
  }, [page] )

  
  return (
    <MediaContext.Provider value ={$}>
      {children}
    </MediaContext.Provider>
  )

}

export const useUser = ():UserState => useStore(store.user)

export const useCurrentUser = ():FBUser => useStore(store.user, ({user}) => user)
// BUG -- 
export const usePage = ():Page => useStore(store.page, ({page}) => page)


export const usePage$ = ():{$:$Page, page:Page} => ({
  $:$.$page,
  page:usePage()
}) 

export const usePresence = () => useStore(store.presence)



export const useUI = (selector?):any/*UIState*/ => useStore(store.ui, selector)
export const useUI$ = (selector?):{$:any/*$UI*/, pm:any/*UIState*/ } => {
  return {
    $:$.$ui,
    pm: useUI(selector)
  }
}



/*
export const useMedia$:(() => {$:$MediaPs, media:MediaState}) = () => {
  return {
    $:$.media,
    media:useStore(store.media)
  }
}

*/
export const usePresence$:(() => {$:$Presence, pm:PresencePM}) = () => {
  return {
    $:$.$presence,
    pm: useStore(store.presence)
  }
}

