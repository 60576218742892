import { createTheme } from '@mui/material/styles';
import { THEME_LEA } from './theme-lea';


const THEME = THEME_LEA  // THEME_ASAD

export const theme = createTheme(
  (THEME as any)
)

// TODO - module augmentation 


//https://dragoshmocrii.com/material-ui-custom-theme-and-typescript/ 


type CustomTheme = {
  [Key in keyof typeof THEME_LEA]: typeof THEME_LEA[Key]
} 

declare module '@mui/material/styles' {
  interface Theme extends CustomTheme { }
  interface ThemeOptions extends CustomTheme { }
}
export default createTheme(theme);



//https://mui.com/material-ui/customization/typography/#adding-amp-disabling-variants


declare module '@mui/material/styles' {
  interface TypographyVariants {
    poster: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    poster?: React.CSSProperties;
    fontFamily:any
  }
  
  interface Palette {  
      slide: {
        bg: string
        LINK: string
        RED: string
        txt: string
      } 
  }


}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    appTitle: true;
    appSubtitle: true;
    editorTxt:true;
  }

}


// -- and for palette:
//https://javascript.plainenglish.io/extend-material-ui-theme-in-typescript-a462e207131f

