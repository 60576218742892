// https://www.youtube.com/watch?v=NH1II2tzGv8
// https://tabsoverspaces.in/posts/create-a-audio-player-in-nextjs/
import Wavesurfer from "wavesurfer.js"; 
//import * as WaveformRegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions";
import * as WaveFormSpectrum from "wavesurfer.js/dist/plugin/wavesurfer.spectrogram"
import { $Evo } from "../useEvos"
import { LOAD_COL, OK_COL, SEL_COL } from "../view/EvoView";
import colormap from "colormap"
// https://github.com/katspaugh/wavesurfer.js/issues/1867
// 
export type WaveEff = {

  init: (showSpectrum, container, spectralContainer) =>  void

  setBuffer: (buffer) => void
  play:() => void
  stop:() => void
  togglePlay: () => void
}


export const WaveEff = ($:$Evo):WaveEff => {
  var container:HTMLElement
  var spectralContainer:HTMLElement

  var audioContext = new AudioContext()
  var ws // :: WaveSurferEff

  const createWs = (c, spectralC) => {
    container = c
    spectralContainer = spectralC
    if (ws) {
      ws.destroy()
      ws = null
    }

    const colors = colormap({
      colormap: 'bathymetry',  //'YlGnBu',//  'viridis',// 'winter',
      nshades: 256,
      format: 'float' 
  });
  

    ws = Wavesurfer.create({
      audioContext,
      container,
      waveColor: LOAD_COL,  //  "#567FFF",
      progressColor:  OK_COL,
      barGap: 2,
      barWidth: 3,
      barRadius: 3,
      cursorWidth: 3,
      cursorColor: SEL_COL,
      height: spectralContainer ? 80 : 100 ,
      plugins: (spectralContainer ?  [WaveFormSpectrum.create({
        wavesurfer: ws,
        container: spectralContainer,
        labels: false,
        height: 110,
        colorMap:colors
    })] : [])

    } );

    ws.on('finish', () => {
      // -- if autoplay 
      if ($.appState.autoLoop()) {
        $.wave.play()
      } else {
        $.mu.playing({isPlaying:false})
      }
    })


  }

  const destroy = () => {
    if (ws) {
      ws.destroy()
    }
  }




  return {
    init: (showSpectrum, container, spectralContainer)  => {  

      createWs(container, showSpectrum ? spectralContainer : null)

    },

    setBuffer: (buffer) => {
      const wasPlaying = ws.isPlaying()
      const t0 = wasPlaying ? ws.getCurrentTime() : -1

      ws.loadDecodedBuffer(buffer)
      const tMax = ws.getDuration()
      
      $.mu.playing({isPlaying:true})

      if (wasPlaying &&  (!isNaN(t0) && t0 > 0 && t0 < (tMax - 0.3) )) {
        ws.play(t0)
      } else {
        ws.play(0)
      }
    },



    play:() => {
      if (ws) {
        $.mu.playing({isPlaying:true})
        ws && ws.play()

      }
    },

    stop: () => {
      if(ws) {
        $.mu.playing({isPlaying:false})
        ws.stop()
      }
    },

    togglePlay: () => {
      const isPlaying = ws.isPlaying()
      $.mu.playing({isPlaying:!isPlaying})

      if (ws.isPlaying()) {
        ws.pause()
      } else {
        ws.play()
      }
    } 


  }




} 

