
import { UserState, UserState0 } from "../../model/page/user/UserState"

import { $ } from "../../anyonic/dsl/mu-types"
import { UserEff } from "./UserEff"
import { $muEff } from "../../anyonic/dsl"
import { CompositeReducerUpdate } from "../../anyonic/dsl/dsl-spec"
import { NavEff } from "../../model/ps/nav/NavEff"



export type $User = $<
    {
      state:UserState
    }, 
    UserEff,
    {},
    {}
  > 



export const createUser$ = (update:CompositeReducerUpdate, $nav:NavEff ):$User => $muEff({
  pm:{
    state:UserState0()
  },
  mu:{},
  refs: {},

  $: ($:$User) => UserEff($, $nav),
  
  update
})
 


