import { mediaEnv, MediaEnv } from "../../model/page/resource/MediaEnv"
import { ResourceManifest } from "../../model/page/resource/ResourceManifest"


const { r } = ResourceManifest


const ROOT =  (process.env.NODE_ENV === 'production') 
   ?  "https://herebedragons.cafetextual.com"  
   :  "http://localhost:3000/"


export const toLocalURL = path => `${ROOT}/${path}`


export const appEnv:MediaEnv<any> = mediaEnv({
    Home:"/",
    Login:"/login",
    
    Register:"/register",
    Logout:"/logout",

    View:"/view"


    /*
    PProject:"/vid/:project",           // project  (all folders)
    PFolder: "/vid/:project/:folder",    //  folder 
    PVideo:  "/vid/:project/:folder/:vid", // video 
    Presence: "/presence",                  // online status
    
    // -- bubble notes
    DProjects:"/dproject",                  // <-- all projects
    DProject: "/dproject/:user/:pname",     // <-- bibliography for each project
    DNotes:  "/dproject/:user/:pname/:ref",  // <-- individual notes
    
    // -- new bubble notes api

    BubbleNotes:"/BubbleNotes/:src/:project/view", 
    //BubbleTags: /BubbleNotes/:src/user/:user/tags"
    BubbleNotesEdit:"/BubbleNotes/:src/:project/edit",
    BubbleNotesTweak:"/BubbleNotes/:src/:project/tweak",

    ProjectView:"/Project/:project/view", 
    ProjectEdit:"/Project/:project/edit",
    ChannelsEdit:"/Project/:project/channels/:user/edit",
    MyNotes:"/MyNotes/:project",
    
    // -- Mesages
    MsgChannels:"channels/:chamber",



    // -- anyonic cods
    Anyon: "/anyon/:anyon",
    Draw: "/draw/:pic",
    BubbleAdmin:"/admin",

      PROJECT: "/project/:user/:pname",  
      BIB: "/bibliography/:user/:pname",
      REF: "/project/:user/:pname/:ref", 

    // -- documents
      DOCS:"/docs/:user/:project", 
      DOC: "/doc/:user/:id",   
    */


  }, {
    Home: _ => {
      return r({})
    },

    /*
    PProject: ({project}) => r({
      index:`${ROOT}/data/${project}.json`
    }),
    PFolder: ({project, folder}) => r({
      videos: `${ROOT}/data/project/${project}/${project}_${folder}.json`
    }),
    PVideo: ({project, folder}) => r({
      videos: `${ROOT}/data/project/${project}/${project}_${folder}.json`
    }),
    
    // -- bubble notes
    DProjects: () => r({
      index: `${ROOT}/data/bubble/index.json`
    }),
    DProject: ({user,pname}) => r({
      project: `${ROOT}/data/bubble/${user}/${pname}/index.json`
    }),
    DNotes: ({user, pname, ref}) => r({
      notes: `${ROOT}/data/bubble/${user}/${pname}/${ref}/index.json`
    }),

    BubbleAdmin: _ => {
      
      return r({}) // 

    }
    // BubbleNotes  ... no static data to load 
    //MsgChannels: ({chamber}) => ... 
 
    //PVideo:   ({project, folder}) => Page('folder', `/pvideo/${project}/${folder}`)
    */
  })

  
  

