import { $Nav } from "./$Nav";



const queryString = require('query-string');

type NavLocation = {pathname:string, search:string}

// -- TODO - extract from page
export type NavEff = {
  to: (rurl:string) => void


  login: () => void
  home: () =>  void
  
  //  CAT_DOCS:(id:string) => navTo(`/cat-docs/${id}`),
  //  CAT_DOCS_VIEW: id => navTo(`/cat-docs-view/${id}`)
  setQuery: (q:any) => void

  setLocation: (location:NavLocation) => void
  location: () => NavLocation

}


export const NavEff = ($:$Nav, navigate:Function):NavEff => ({
  
  // -- the key navigation effect
  to: rurl => navigate(rurl),

  login: () => $.to("login"),

  home: () =>  $.to("/"),

  //  CAT_DOCS:(id:string) => navTo(`/cat-docs/${id}`),
  //  CAT_DOCS_VIEW: id => navTo(`/cat-docs-view/${id}`)

  setQuery: (q:any) => {
    const {location} = $.mu().nav
    const search = q ? queryString.stringify(q) : ""
    
    if (location.search !== search) {
      const path = location.pathname + (search && search.length > 0 ? "?" + search : "")
      $.to(path)
    }
  },
  setLocation: location => $.mu.nav({location}),
  location: () => $.mu().nav.location

})