import { MediaEnv } from "../../page/resource/MediaEnv"
import { Page } from "../../page/resource/PageState"



import { PageLoadEff } from "./PageLoadEff"
import { NavEff } from "../nav/NavEff"
import { $Page } from "./$Page"


export type PageEff = {
  
  page: {
    setLocation: (location:any) => Page
    resolve: (rurl:string) => Page
  }


  $nav: NavEff
  pg:PageLoadEff

}

export const PageEff = ($:$Page, env:MediaEnv<any>, $nav:NavEff):PageEff => {

 return {
    
    pg:PageLoadEff($),
    $nav,


    // -- this is an internal effect, should not be exposed 
    page: {

      setLocation: (location) =>  {
      


        const {pathname, search} = location
        const path = pathname + (search ? `${search}` : "")

        const {page, cache} = env.resolvePage(path, $.refs().cache ) 


        $.$nav.setLocation(location)
        $.ref.cache(cache)
        $.mu.page.Put(page) 

      
        return page

    },

    resolve: (rurl:string) => {
      const {cache:cache0}= $.refs()

      const {page, cache} = env.resolvePage(rurl, cache0)
      $.ref.cache(cache)
      $.mu.page.Put(page)

      return page
    }

  }



}

    
  
}


/*
export type PageAPI = {
  setLocation: (location:any, history:any) => any
  resolve: (rurl:string) => any

}*/
    



  