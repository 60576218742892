
const arcBlue =    '#0B72B9'  // '#0097a7' //
const arcOrange =  '#f06292' // '#b2ebf2' // '#FFBA60'

const lPink = '#e7afbb'
const dPink = '#f90b6d'
const headingPink = '#e70d3d'
const black  = "#000000"

const font1 = "'Alegreya', serif;";
const font2 = "'Ubuntu', sans-serif;";
const font3 = "'Dancing Script', cursive";
// --- slide palette
// -- TODO - more semantic names
export const BG = "#222222"  // <-- not too dark, especially when slighly transparent over image
export const RED = "#E65634"  // <-- from fre and ash. nice underline too
export const ORANGE = "#C24C23"
const txt = "white" 
export const LINK = "#999999"  // <-- grey for text link (turns red on hove, swaps colour /w underline)
export const YELLOW = "#ffc300"

export const THEME_LEA = {
  spacing: 2,
  palette: {
    _RED: RED,
    slide: {
      bg: BG,
      LINK: LINK,
      YELLOW,
      RED: RED,
      txt: txt,
      font1 
    }, 
    common: {
      blue: `${arcBlue}`,
      orange: `${arcOrange}`,
      lpink:  `${lPink}`, // <-- light pink
      dpink: `${dPink}`, // <-- dark pink
      headingPink: `${headingPink}`,
      
      black: `${black}`
    },
    primary: {
      main: `${black}`
    },
    secondary: {
      main: `${arcOrange}`
    }
  },
  primary: {
    main:`${black}`
  },
    // light
    // main
    // dark
  // secondary

  // error
  // text   <-- opacities etc
  // action   <-- opacities 
      // action, hover, selected etc 
 
  typography: {
    fontFamily:  font1,  //  
    fontsize: 14,
    avatar: {
      fontFamily:font3
    },
    h1: {
      fontSize: 50
    },
    h3: {
      fontWeight: 300
    },

    // -- custom typeography
    appTitle: {
      fontSize: 30,
      fontFamily:  font1,  
      lineHeight: 1.1
    }, 
    appSubtitle: {
      fontSize:18,
      fontFamily:  font1, 
      lineHeight: 1

    },

    editorTxt: {
      fontSize:50,
      fontFamily: font2, 
      lineHeight: 1
    }


  }
 
  
} 
