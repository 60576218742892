import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { $Evo, EvoAppPM } from '../useEvos';
import { EvoLeafModel } from '../model/EvosModel';
import { CircularProgress, Slider, ToggleButton } from '@mui/material';
import LoopIcon from '@mui/icons-material/Loop';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import WaterIcon from '@mui/icons-material/Water';

const H = 70

export const  MediaControlCard = ({pm, $}:{pm:EvoAppPM, $:$Evo}) =>  {

  const {evo, evoVolume, pending, pendingStatus} = pm.playing



  const isLoading = (evo && evo  !== pending)  

  if (isLoading) {
    console.log('x')
  }
  const {img, alt}  = evo ?  {img:toImg(evo, pm), alt:evo.lib} :  {img:"", alt:"none"};
  const text = evo ? `${evo.num} - ${evo.name} (${evo.section})` : " -- select an evo -- "
  
  const handleVolumeChange = (event: Event, newValue: number | number[]) => {
    if (evo) {
      $.audio.setEvoVolume(evo.uri, newValue as number);
    }
  };

  

  return (
    <Card  sx={{ display: 'flex', h:H, w:"100%"}}>
 

        <CardMedia
          component="img"
          sx={{ width:H, height: H, opacity: isLoading ? 0.3 : 1 }}
          image={img}
          alt={alt}/>     
          
           {isLoading &&    
            <CircularProgress sx={{
                padding:'14px', width:H, height: H,position: 'absolute',
              }}/> }


     

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h5">
          {text}
          </Typography>
         

            
        </CardContent>
       
        
      </Box>
      <Box  sx={{ padding:'21px', right:'0px', display: 'absolute', alignItems: 'right', pl: 1, pb: 1 }}>
              
        <LoopStateToggle $={$} pm={pm}/>
      </Box>
      <Box width={150}>
        <Slider sx={{top:'20px'}}
          size="small"
          color='secondary'
          defaultValue={evoVolume*100}
          onChange={handleVolumeChange}

          aria-label="Small"
          valueLabelDisplay="auto"
        />
    </Box>
    </Card>
  );
}



const LoopStateToggle = ({$,pm}:{$:$Evo, pm:EvoAppPM}) => (
  <div >
    <span style={{padding:5}}>
  
    {pm.playing.isPlaying
      ? <PauseCircleOutlineIcon onClick={$.wave.togglePlay}/>
      : <PlayCircleIcon onClick={$.wave.togglePlay} />
    }
    </span>

    <ToggleButton style={{ top:"-7px", padding:10, width:20, height:20}}
      value="check"
      size='small'
      selected={pm.appState.autoLoop}
      onChange={$.appState.toggleAutoLoop}
      color={pm.appState.autoLoop ? "secondary" : "primary"}
      >
        <LoopIcon />
    </ToggleButton>

    <ToggleButton style={{ top:"-7px", padding:10, width:20, height:20}}
      value="check"
      size='small'
      selected={pm.appState.showSpectrum}
      onChange={$.appState.toggleShowSpectrum}
      color={pm.appState.showSpectrum ? "secondary" : "primary"}
      >
        <WaterIcon />
    </ToggleButton>



  </div>)




const toImg = (evo:EvoLeafModel, pm:EvoAppPM) => {

  // -- FIX_THIS - this duplicates code, and should be consolidated in the base entities
  const ROOT =  (process.env.NODE_ENV === 'production') 
  ?  "https://herebedragons.cafetextual.com"  
  :  "http://localhost:3000/"

  const IMG    = `${ROOT}/img/lib`

  return `${IMG}/${evo.lib}.jpeg`

}


