import FB from "../../firebase/Firebase"
import { FBUser } from "../../model/page/user/FBUser"
import {  UserState0 } from "../../model/page/user/UserState"
import { NavEff } from "../../model/ps/nav/NavEff"

import { $User } from "./$User"



export type UserEff = {
  start: () => void
  
  $nav:NavEff

  SetUserLoading: () => void
  SetUser: (user:FBUser) => void
  ClearUser: () => void

} 




var _started = false  // <-- temporary hack to prevent multiple instantiation
 

export const UserEff = ($:$User, $nav:NavEff):UserEff => {

  

  return {
    $nav,

    start:() => $.ps(_ => {
    
      if (_started) {   // <-- FIX_THIS hack 
        return
      }

      _started = true
    
      FB.auth.onAuthStateChanged(user => {
        if (user) {
          const usr:FBUser = toFBUser(user)
          //yield $$.SET_USER(usr)       // <-- possible that the navigation is causing the user to log out
          
          const location = $.$nav.location()
    
          $.SetUser(usr)
          // FIX_THIS - this breaks the SPA
          if (location) {
            const {pathname, search} = location
            $.$nav.to(pathname + (search && search.length > 0 ? `${search}` : ""))
          } else {
            $.$nav.home()  // <-- navigate. Really would like this to be batched
          }
        } else {
          //console.log('-- no user --')
          $.$nav.login() 
          //yield $$.CLEAR_USER()   
          $.ClearUser()
        }
      })
      return () => {
        console.log('done')
        _started = false
      }
    }), // note that this returns unsubscribe, to ensure process is stopped on unmount
  

    
    

    SetUserLoading: () => $.mu.state({isLoading:true}),
    SetUser: (user:FBUser) => $.mu.state({user, isLoading:false}),
    ClearUser: () => $.mu.state.Put(UserState0())



  }
}




// TODO - type as  firebase.User
export const toFBUser = (user):FBUser => 
  FBUser( user.uid,  user.displayName || emailToName(user.email), user.photoURL!)


const emailToName = (v:string) => {
  var i = v.indexOf("@") 
  var out = v.substring(0,i)
  return out
}








