import { useEffect, useMemo, useState } from "react"
import { $muEff} from "../anyonic/dsl"
import { $ } from "../anyonic/dsl/mu-types"
import { EvoAppStateEff, EvoAppStatePM, EvoAppStatePM0 } from "./eff/AppStateEff"
import { AudioPM, AudioPM0, EvoAudioEff } from "./eff/EvoAudioEff"
import { EvoPlayerEff, PlayerPM, PlayerPM0 } from "./eff/EvoPlayerEff"

import { WaveEff } from "./eff/WaveEff"
import {evoData } from "./model/evoData"
import { createPM, EvoPM, EvosModel, toFirstEvo } from "./model/EvosModel"


export type EvoAppPM = {
  evoPm:EvoPM,
  appState:EvoAppStatePM,
 
  audio:AudioPM,

  playing:PlayerPM
 

}

export type EvoRefs = {
  waveContainer:HTMLDivElement
  spectralContainer:HTMLDivElement
}

const pm0 = (data:EvosModel):EvoAppPM => { 

  const ROOT =  (process.env.NODE_ENV === 'production') 
  ?  "https://herebedragons.cafetextual.com"  
  :  "http://localhost:3000/"


  const AUDIO  = `${ROOT}/audio`
  const IMG    = `${ROOT}/img/lib`


  const evoPm = createPM(data , {AUDIO, IMG})
  const firstEvo = toFirstEvo(evoPm,"demo1", "75")

  return {

    evoPm,
    appState:EvoAppStatePM0,
    audio: AudioPM0,
    playing:PlayerPM0(firstEvo)
  }
}

  

export type $EvoEff = {
  player:EvoPlayerEff
  audio:EvoAudioEff
  appState:EvoAppStateEff
  wave:WaveEff
}

export type $Evo = $<EvoAppPM, $EvoEff, {}, EvoRefs>



export const useEvoPs = ():{$:$Evo, pm:EvoAppPM} => {
  const [pm, update] = useState(() => pm0(evoData))

  const $:$Evo = useMemo( () => $muEff({
    pm,

    refs:{
      waveContainer:null as any,
      spectralContainer:null as any
    },
    mu: {},

    $: ($:$Evo) => ({
      player:EvoPlayerEff($),
      appState:EvoAppStateEff($),
      audio:EvoAudioEff($),
      wave:WaveEff($)
    }),
    
    update,
    init: _ => {
      console.log('disabling update ')
    }
  }), [])

 
  useEffect(() => {

    const {waveContainer, spectralContainer} = $.refs()

    if (!waveContainer) {
      throw new Error(" no container for waveform")
    }
    $.wave.init(pm.appState.showSpectrum, waveContainer, spectralContainer)
    $.audio.play($.mu.playing().evo)

  },[pm.appState.showSpectrum])


  return {$, pm}

} 