import { $Evo } from "../useEvos"
import {EvoLeafModel} from "../model/EvosModel"


export type EvoStatus = {
  ok?:boolean, 
  loading?:boolean, 
  failed?:boolean
}

export type PlayerPM = {
  isPlaying?:boolean
  evo:EvoLeafModel
  evoVolume:number
  evoStatus:EvoStatus
  pending?:EvoLeafModel
  pendingStatus?:EvoStatus
}

export const PlayerPM0 = (evo:EvoLeafModel):PlayerPM => ({
  evo,
  evoVolume:1,
  evoStatus:{},
})

export type EvoPlayerEff = {
  playEvo: (evo:EvoLeafModel) => void
  playEvoByUri: (uri:string) => void
  setPending: (uri:string) => void
}


export const EvoPlayerEff = ($:$Evo):EvoPlayerEff => {
  const context:AudioContext = new AudioContext

  var _currentBuffer:AudioBuffer

  const playAt = t => {

      var source:AudioBufferSourceNode = context.createBufferSource(); // creates a sound source
      source.buffer = _currentBuffer;                    // tell the source which sound to play
      source.connect(context.destination);       // connect the source to the context's destination (the speakers)
      source.start(0);  

  }


  return {

    setPending: (uri:string) => {
      const pendingStatus = $.audio.evoStatus(uri)
      const pending = $.mu.evoPm().byURI[uri]

      $.mu.playing({
        pending, pendingStatus
      })
    },

    playEvo: evo => {

      const buffer = $.audio.getBuffer(evo.uri)
      const status = $.audio.evoStatus(evo.uri)

      const evoVolume:number = 1

      $.mu.playing.Put({
        evo,
        evoVolume,
        pending:evo,
        evoStatus: {ok:true}
      })

      if (buffer) {
        $.wave.setBuffer(buffer)
      } else {
        $.wave.stop()
      }

    }, 

    playEvoByUri: (uri:string) => {
      const pm = $.mu.evoPm()
      const evo:EvoLeafModel = pm.byURI[uri]
      $.player.playEvo(evo)

    }
  }
    
    
}