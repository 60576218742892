import { Grid, IconButton } from "@mui/material"
import { EvoLeafModel, LibPM, SectionPM } from "../model/EvosModel"
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { $Evo, EvoAppPM } from "../useEvos"
import { MediaControlCard } from "./MediaCard";

import { Box, SxProps } from "@mui/system";

// https://coolors.co/palette/f94144-f3722c-f8961e-f9844a-f9c74f-90be6d-43aa8b-4d908e-577590-277da1


export const SEL_COL = "#9b2226" 
export const LOAD_COL =  "#277DA1" // green
export const OK_COL =  "#43AA8B"  // blue
export const NOT_LOADED_COL = "#AAAAAA" // greay

const sectionSx:SxProps = {
  color:"black",
  fontSize:22,
  display:'inline-block',

}

const sectionSxSelected:SxProps = {
  ...sectionSx,
  color:SEL_COL,
}



export const EvoView = ({$,pm}:{$:$Evo, pm:EvoAppPM}) => {
 
  const data = pm.evoPm.model.demo1[75]

  const sel:string = pm.playing.evo?.uri  || "" // <-- currently selected
  const {status} = pm.audio

  return (<Box sx={{ flexGrow: 1 }}>

    <MediaControlCard pm={pm} $={$}/>
    <div ref={$.ref.waveContainer}/>
    {pm.appState.showSpectrum &&
       <div ref={$.ref.spectralContainer}/>}



    <Grid sx={{top:'5px'}} container spacing={0.5}>


    {data.map((lib:LibPM) => (
      <Grid item sx={{top:1, left:1}} xs={6} key={lib.name} >

        <span style={{float:"left",  padding:"7px", paddingLeft:'8px'}  }>
          <img  src={lib.img} alt={lib.name} style={{width:67, height:67 }} />
        </span >
      

        {lib.sections.map((sec:SectionPM) => (
        <div style={{display:"inline", paddingTop:'8px'}} key={sec.name}>

            <Box sx={containsSel(sec, sel ) ? sectionSxSelected : sectionSx  }>
              {sec.name}{": "}
            </Box>
      

            {sec.evos.map( (evo:EvoLeafModel, n) =>  {

              const isLoading = status[evo.uri] === "loading"
              const isLoaded = status[evo.uri] === "ok"
              const isSel = evo.uri === sel

              const sx:SxProps =  sxFromStatus(isLoading, isLoaded, isSel)
              
             

              return (
                <IconButton  key={evo.uri}  sx={{...sx, mt:"-7px"}}
                  size="small" onClick={_ => $.audio.play(evo)}>
                  {evo.numLabel}  {isLoading && 
                    <span style={{color:LOAD_COL}}>*</span>}
                </IconButton>)   
            })}
            {" "}
        </div>
      ))}


          </Grid>))} 
      </Grid>
  </Box>)
  
      
}

/*

<div onClick={_ => {
    $.audio.play(undefined)
  }}>Play</div>   
    <h1>1</h1>
    <img src={process.env.PUBLIC_URL + "img/lib/LCOT.jpg"} alt="image lcot"/>
    <h1>2</h1>
    <img src="localhost:3000/public/img/lib/LCOT.jpg" alt="image lcot"/>
    <h1>3</h1>
    <img src="public/img/lib/LCOT.jpg" alt="image lcot"/>




  

  </div>

}

/*
  {pm.model.libs.map(lib =>  (<div>
      <img src={`/public/img/lib/${lib}.jpeg`}
      
      alt={`Spitfire Audio Library ${lib}`}/>
       <h2>Text goes here for {lib}</h2>
      </div>))

      */


  export const Play = ({pm, $}) => <IconButton>
    {pm.playing.isPlaying ? <PlayCircleOutlineIcon/> : <StopCircleIcon/>} 
  </IconButton>

  const containsSel = (sec:SectionPM, sel:string) => sec.evos.reduce(
      (v, evo) => (v || evo.uri === sel), 
      false)


  
  const LOADING_SX = {color:LOAD_COL, mr:-1, mb:-1}  
  const SEL_SX     = {color: SEL_COL, mr:-1, mb:-1} 
  const LOADED_SX  = {color: OK_COL, mr:-1, mb:-1}
  const NOT_LOADED_SX = {color : NOT_LOADED_COL, mr:-1, mb:-1}

  const sxFromStatus = (isLoading, isLoaded, isSel) => 
    isLoading ?            LOADING_SX
              : isSel    ? SEL_SX
              : isLoaded ? LOADED_SX
                         : NOT_LOADED_SX

