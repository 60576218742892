import {  useCallback } from "react";

// -- TODO - separate from react dependency
import {useSyncExternalStore} from  "react"  // 'use-sync-external-store';





// -- extremely minimal, shallow, store 
export type SStore<A> = {
  getState: () => A,
  setState: (v:A) => void
  subscribe: (fn:((v:A) => void)) => void
}


export const createStore = <A,>(initialState?:A):SStore<A> => {
  let state = initialState;
  const getState = () => state!;
  const listeners = new Set();
  
  const setState = (v) => {
    if (v !== null && v !== state) {   // <-- very shallow store
      state = v
      listeners.forEach((l:any) => l());
    }
  }
  const subscribe = (listener) => {
    listeners.add(listener);
    return () => listeners.delete(listener);
  }
  return {getState, setState, subscribe}
}

const id = v => v


export const useStore = (store, selector?) => {
  selector = selector || id
  return useSyncExternalStore(
    store.subscribe, 
    useCallback(() => selector(store.getState()), [store, selector])
  )
}

