

import {  collection, getDocs } from 'firebase/firestore';

import {ref, set } from "firebase/database";
import FB from './Firebase';



var _fbPs // singleton source of base firebase services

export type Timestamp = any





export const fbPs = () => {

  if (!_fbPs) {
    _fbPs = {
      // -- services
      '<auth|': FB.auth,
      '<connected|': ref(FB.database,".info/connected"),
       
      // -- concieveably, this might be injected via app name

      '<users|':ref(FB.database, "users"),   
      '<presence|': ref(FB.database, "presence"),
      '<typing|': ref(FB.database, "typing"),  // <-- TODO - integration this with presence/status



      // -- https://firebase.google.com/docs/firestore/query-data/get-data#web-version-9

      // -- project resources, 
      '<Projects|':  collection(FB.db,  "Projects"),
      // -- sources - be referenced by multiple projects
      '<BubbleSrc|': collection(FB.db,  "BubbleSrc"),
      // -- notes 
      '<BubbleNotes|': collection(FB.db, "BubbleNotes"),
      '<BubbleTags|': collection(FB.db, "BubbleTags"),



      listeners:[]

    }

    _fbPs = {
      ..._fbPs,
      //   BubbleNotes/:BubbSrc
      // 
      // Projects/$project/refs/core=>Entity<RefDocPM>
      projectCoreRef: (projectId:string) => _fbPs['<Projects|'].doc(projectId).collection('content').doc("refs"),
      
      bubbleSrcRef: () => _fbPs['<BubbleSrc|'],
      bubbleSrcDocRef: (ref:string) => _fbPs['<BubbleSrc|'].doc(ref),
      //BubbleSrcRef: (id:string|undefined) => _fbPs['<BubbleNotes|'].doc("src").collection("src"),
      //BubbleReading: (id:string) => _fbPs['<BubbleNotes'].doc('')
      
      
      bubbleNoteReading: (reading) => _fbPs['<BubbleNotes|'].doc(reading),
      // <BubbleNotes/{:reading}/  <-- this can be queried etc.
      //        .notes.data   :: Entity<BubbleReading>  <-- cannonical data
      //        .notes.tweaks :: Entity<BubbleTweaks>    
      bubbleNoteCollectionData: (reading) => _fbPs['<BubbleNotes|'].doc(reading).collection("notes"),

      bubbleNoteData: (reading) => _fbPs['<BubbleNotes|'].doc(reading).collection("notes").doc("data"),
      bubbleNoteTweak: (reading) => _fbPs['<BubbleNotes|'].doc(reading).collection("notes").doc("tweaks"),

      bubbleNotesQuery: (user:string, src:string) => {
        return _fbPs["<BubbleNotes|"]
         //   .where("owner", "==", user)
            .where("src", "==", src)
      }, 

      getTagCollectionRef: (readingId:string, user:string) => {
        return _fbPs['<BubbleTags|'].doc(readingId).collection(user)
      },

      // -- documents
      getAnyonDoc: (id:string) => {
        return _fbPs['<Anyon|'].doc(id)
      }


    }
  }

  
  return _fbPs
}
