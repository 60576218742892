import { morph } from "../../../anyonic/dsl/dsl-api"
import { Cache } from "../../page/resource/PageCache"
import { Page, toPath , isReady, shouldLoad} from "../../page/resource/PageState"
import { ResourceManifest } from "../../page/resource/ResourceManifest"

import { axiosGet } from "../../../ps/resource/json/loadJsonP"
import { $Page } from "./$Page"


export type PageLoadEff = {
  
  // -- formerly mutation api
  Update: (rurl:string, updateFn:(p:Page) => Page , andSelect?:boolean,) => void
  LOAD_START: (rurl:string, andUpdate?:boolean) => void
  LOAD_ERROR: (rurl:string, e:{message:string}) => void
  LOAD_COMPLETE: (rurl:string, data:any) => void
  

  load:(page:Page) => void

}

export const PageLoadEff = ($:$Page):PageLoadEff => {

    var loading = {}  // <-- temporary state


    return {



    Update: (rurl, updatePage:(page:Page) => Page, andSelect?:boolean) =>  {

      // -- wrapper around page cache

      const {cache} = $.refs()
      const page0:Page = Cache.retrieve(rurl, cache) // <-- TODO - cache management is precicely what we wich to avoid
      
      if (page0) {
        // -- mutate page
        const page = updatePage(page0)    // <-- perform mutation on page
  
        const selectedPage:Page = $.mu.page()

        if (andSelect || (toPath(selectedPage) === rurl)  ) {
          $.mu.page.Put(page)  // <-- this selects
        }
    
        $.ref.cache( Cache.update(rurl, page,cache) )
      }


    },
    

    LOAD_START: (rurl, andSelect?:boolean) => $.pg.Update(rurl, setLoading, andSelect),

    LOAD_ERROR: (rurl, e) => mu => $.pg.Update(rurl, setError(e)),

    LOAD_COMPLETE: (rurl, data) => $.pg.Update(rurl, setData(data) ),



    load: () => {



      const {page} = $.mu()
      const {rurl} = page
    
      if (loading[rurl]) {
        return
      }

      if (!isReady(page)) {


      
        loading[rurl] = true // <-- mutable state ...


        
        const url = ResourceManifest.toSimpleUrl(page.resource.manifest)

        if (url && shouldLoad(page)) {

          $.run(function*($$)  {
            
            yield $$.wait(1)
            $.pg.LOAD_START(rurl)
            
            // console.log(` --- loading --- ${ url}`)
            const {ok, v, errs} = yield $$.ps( axiosGet(url) ) 
            

            loading[rurl] = false

            if (ok) {
              $.pg.LOAD_COMPLETE(page.rurl, v )
            } else {
              const err = errs[0]
              $.pg.LOAD_ERROR(page.rurl, err)
            }

          })

        }
      }
    },

  }
}



// -- lenses
const setLoading = (page:Page):Page => morph(page, { 
  status: morph(page.status, { isLoading:true, isReady:false })
}) 


const setData = data => (page:Page) => morph(page, {
  data, 
  status:morph(page.status, {isLoading:false, isReady:true, errorMsg:undefined })
}) 






const setError = (e:{message:string}) => (page:Page):Page => morph(page, {
  status:morph(page.status, {
    isLoading:false, 
    isReady:false,   
    errorMsg: [`error loading "${page.resource.rurl}" msg: "${e.message || " (unknown error message)"}"`],
  })
})


    
    