import { $Evo } from "../useEvos"

export type EvoAppStatePM = {
  autoLoop:boolean
  showSpectrum:boolean
}


export const EvoAppStatePM0 = {
  autoLoop: true,
  showSpectrum: true
}

export type EvoAppStateEff = {
  toggleAutoLoop: () => void 
  toggleShowSpectrum: () => void

  autoLoop: () => boolean
}

export const EvoAppStateEff = ($:$Evo):EvoAppStateEff => ({
  toggleAutoLoop: () => $.mu.appState.Toggle({autoLoop:true}),
  toggleShowSpectrum: () => $.mu.appState.Toggle({showSpectrum:true}),

  autoLoop: () => $.mu.appState().autoLoop

})  
