import {firebaseConfig} from './fb-config';
import firebase from 'firebase/app';

import { initializeFirestore, collection, getDocs, CACHE_SIZE_UNLIMITED, serverTimestamp } from 'firebase/firestore';
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

import { initializeApp,  } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { enableMultiTabIndexedDbPersistence, Firestore } from "firebase/firestore"; 
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

//const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);


//import 'firebase/auth'
//import 'firebase/firestore'
//import 'firebase/database'
//import "firebase/storage";

  
export class Firebase {
  
  app 
  auth:any
  db:Firestore

  TIMESTAMP:any
  database:any
  storage:any

  constructor() {
      const app = this.app = initializeApp(firebaseConfig)
      const db = this.db = initializeFirestore(app, {
        cacheSizeBytes: CACHE_SIZE_UNLIMITED
      })

    //const analytics = getAnalytics(app);
    // https://firebase.google.com/docs/auth/web/auth-state-persistence

    enableMultiTabIndexedDbPersistence(db)
      .then(_ => {
          console.log('offline enabled')
      })
      .catch((err) => {
      if (err.code === 'failed-precondition') {
          throw new Error("failed b/c of multiple tabs")  
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
      } else if (err.code === 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
          throw err
      } else {  
        // -- not sure.
        throw err
      }
    });


    this.auth = getAuth()


    this.TIMESTAMP = () => serverTimestamp() 
    this.database = getDatabase()
    //this.storage = getStorage(app, "????")

  }

  async register(name, email, password) {
    const newUser = await this.auth.createUserWithEmailAndPassword(email, password)

    await newUser.user.updateProfile({
      displayName: name,
      photoURL: `/public/avatars/${name}`
    })
  }


  async login(email, password) {
    var result =  await this.auth.signInWithEmailAndPassword(email, password)
    return result 
  }

  async logout() {
    await this.auth.signOut()
  }

  async resetPassword(email) {
    await this.auth.sendPasswordResetEmail(email)
  }

}

// -- singleton firebase instance 
var FB = new Firebase()


export default FB 


