import { HandlerSpec } from "./dsl-api"
import { assert } from "./dsl-spec"

export const BATCH = Symbol('batch')

/**
 * Batches reducer commands as per the 
 * 
 * 
 *  cmd: {x
 *      ns: "pm"  <-- model name space
 *      cmd: ...  <-- standard reducer command, defined by reducer, $.mu.pm.MyCmd 
 *      o: ...     -- simple property mutations $.mu(mu => mu.prop(newValue))
 *  } 
 */
export type BatchCmd = 
  {ns:string, o:any}      // <-- object of properties to mutate, ie {name:newName,  etc}
  | {ns:string, cmd:any}  // <-- reducer command

export type BatchCmds = {$:Symbol, queue:BatchCmd[], retNs?:string }

export const batchReduce = (cmd:BatchCmds, spec:HandlerSpec):any => {
  
  const {queue, retNs} = cmd
  
  const {refs} = spec
 
  if (queue) {

    var visited:any = undefined;

    (queue as any[]).forEach(({ns, o, cmd}) => {  // key ,ages, action 
      // <-- pull the old value 
      if (!refs[ns]) {
        throw new Error(`unknown model ns ${ns}`)
      }
      const v0 = (visited && visited[ns]) ||  refs[ns].current  // FIX_THIS - two sources of truth ref[ns] & state0
      var v1

      // -- i. we have an object of properties to change o = {p1:v, p2:v, etc} 
      //       if we

      if (o) {   // <-- object of simple key values to change
          // TODO - could check for property changes here
        const changed = Object.keys(o).reduce((changed,k) => (changed = changed || o[k] !== v0[k]), false)
        if (changed) {
          v1 =  {...v0, ...o}   // <--  1st instance, simple state mutation
        }
      } else if (cmd) {
        // commands are wrapped (perhaps excessibely)
        // {ns:"pm", cmd:{$:"ns::DoCmd", {$:"Cmd", ... }}  
        const handler = spec.handlers[cmd.$] || spec.byNs[ns].handlers[cmd.$] 
        assert(handler, `unknown command ${ns}::${cmd.$}`)
        
        // -- this is the reducer fn (state, cmd) => state
        v1 = handler.fn(v0 , cmd.v);  // <-- this updates state etc
      }
      
      if (v1 && v1 !== v0) {
        visited = visited || {} 
        visited[ns] = v1
      }
        
    })

    if (visited) {
      spec.update(visited)   // this will mutate the main state
    }

  }
  
  const out = spec.stateRef.current // <-- FIX_THIS - abstract
  return retNs ? out[retNs] : out
}
 
export const batchMutate = (ns:string, o:any, retNs?):BatchCmds => ({$:BATCH, queue:[{ns, o}], retNs })
export const batchQueue = (queue:BatchCmd[], retNs?:string):BatchCmds => ({$:BATCH, queue, retNs})
export const batchCmd = (cmd:any, ns:string):BatchCmds => batchQueue([{ns, cmd}],ns)