import { EvosModel } from "./EvosModel"
export const evoData:EvosModel = {
  "evos": {
    "libNames": [
      "OACE",
      "OAE",
      "TND",
      "FS",
      "CFS",
      "IS",
      "OS",
      "EWC",
      "LCOT"
    ],
    "libs": [
      {
        "libName": "OACE",
        "sections": [
          {
            "section": "Subtle",
            "evos": [
              {
                "num": "01",
                "name": "Sul Tasto Long"
              },
              {
                "num": "02",
                "name": "Sul Tasto Wave (Long)"
              },
              {
                "num": "03",
                "name": "Sul Tasto Wave (Short)"
              },
              {
                "num": "04",
                "name": "Sul Tasto Ord"
              },
              {
                "num": "05",
                "name": "Sul Tasto Pont"
              },
              {
                "num": "06",
                "name": "Detached Waves"
              }
            ]
          },
          {
            "section": "Thrills",
            "evos": [
              {
                "num": "07",
                "name": "Sul Tasto Pulse (Trem)"
              },
              {
                "num": "08",
                "name": "Sul Tasto Trem"
              },
              {
                "num": "09",
                "name": "Art Harm Trills"
              },
              {
                "num": "10",
                "name": "Sul Tasto Episodic Trems"
              },
              {
                "num": "11",
                "name": "Sul Pont Tast Trems"
              }
            ]
          },
          {
            "section": "Episodic",
            "evos": [
              {
                "num": "12",
                "name": "Sul Tasto Pulse"
              },
              {
                "num": "13",
                "name": "Sudden Molto Vib"
              },
              {
                "num": "14",
                "name": "Sudden Trem"
              },
              {
                "num": "15",
                "name": "Sudden Sul Pont"
              }
            ]
          },
          {
            "section": "Dissonant",
            "evos": [
              {
                "num": "16",
                "name": "Sul Tasto Pitch (Slow)"
              },
              {
                "num": "17",
                "name": "Episodic Pitch"
              },
              {
                "num": "18",
                "name": "Wow Effect"
              },
              {
                "num": "19",
                "name": "Slow Flurry Pitch"
              }
            ]
          },
          {
            "section": "Waves",
            "evos": [
              {
                "num": "W01",
                "name": "Waves"
              },
              {
                "num": "W02",
                "name": "Waves Trem"
              },
              {
                "num": "W03",
                "name": "Waves vib"
              }
            ]
          }
        ]
      },
      {
        "libName": "OAE",
        "sections": [
          {
            "section": "Subtle",
            "evos": [
              {
                "num": "01",
                "name": "Sul Tasto"
              },
              {
                "num": "01v1",
                "name": "Sul Tasto"
              },
              {
                "num": "01v2",
                "name": "Sul Tasto"
              },
              {
                "num": "01va",
                "name": "Sul Tasto"
              },
              {
                "num": "01vc",
                "name": "Sul Tasto"
              },
              {
                "num": "02",
                "name": "Long Wave"
              },
              {
                "num": "02v1",
                "name": "Long Wave"
              },
              {
                "num": "02v2",
                "name": "Long Wave"
              },
              {
                "num": "02va",
                "name": "Long Wave"
              },
              {
                "num": "02vc",
                "name": "Long Wave"
              },
              {
                "num": "03",
                "name": "Multi Waves"
              },
              {
                "num": "03v1",
                "name": "Multi Waves"
              },
              {
                "num": "03v2",
                "name": "Multi Waves"
              },
              {
                "num": "03va",
                "name": "Multi Waves"
              },
              {
                "num": "03vc",
                "name": "Multi Waves"
              },
              {
                "num": "04",
                "name": "Tasto to Ord"
              },
              {
                "num": "04v1",
                "name": "Tasto to Ord"
              },
              {
                "num": "04v2",
                "name": "Tasto to Ord"
              },
              {
                "num": "04va",
                "name": "Tasto to Ord"
              },
              {
                "num": "04vc",
                "name": "Tasto to Ord"
              },
              {
                "num": "05",
                "name": "Tasto to Pont"
              },
              {
                "num": "05v1",
                "name": "Tasto to Pont"
              },
              {
                "num": "05v2",
                "name": "Tasto to Pont"
              },
              {
                "num": "05va",
                "name": "Tasto to Pont"
              },
              {
                "num": "05vc",
                "name": "Tasto to Pont"
              }
            ]
          },
          {
            "section": "Thrills",
            "evos": [
              {
                "num": "06",
                "name": "Tasto Trem"
              },
              {
                "num": "06v1",
                "name": "Tasto Trem"
              },
              {
                "num": "06v2",
                "name": "Tasto Trem"
              },
              {
                "num": "06va",
                "name": "Tasto Trem"
              },
              {
                "num": "06vc",
                "name": "Tasto Trem"
              },
              {
                "num": "07",
                "name": "Tasto Trem Varispeed"
              },
              {
                "num": "07v1",
                "name": "Tasto Trem Varispeed"
              },
              {
                "num": "07v2",
                "name": "Tasto Trem Varispeed"
              },
              {
                "num": "07va",
                "name": "Tasto Trem Varispeed"
              },
              {
                "num": "07vc",
                "name": "Tasto Trem Varispeed"
              },
              {
                "num": "08",
                "name": "Med to Fast Trem"
              },
              {
                "num": "08v1",
                "name": "Med to Fast Trem"
              },
              {
                "num": "08v2",
                "name": "Med to Fast Trem"
              },
              {
                "num": "08va",
                "name": "Med to Fast Trem"
              },
              {
                "num": "08vc",
                "name": "Med to Fast Trem"
              },
              {
                "num": "09",
                "name": "Hectic 8ve Trills"
              },
              {
                "num": "09v1",
                "name": "Hectic 8ve Trills"
              },
              {
                "num": "09v2",
                "name": "Hectic 8ve Trills"
              },
              {
                "num": "09va",
                "name": "Hectic 8ve Trills"
              },
              {
                "num": "09vc",
                "name": "Hectic 8ve Trills"
              }
            ]
          },
          {
            "section": "Episodic",
            "evos": [
              {
                "num": "10",
                "name": "Vibrato"
              },
              {
                "num": "10v1",
                "name": "Vibrato"
              },
              {
                "num": "10v2",
                "name": "Vibrato"
              },
              {
                "num": "10va",
                "name": "Vibrato"
              },
              {
                "num": "10vc",
                "name": "Vibrato"
              },
              {
                "num": "11",
                "name": "Sul Pont"
              },
              {
                "num": "11v1",
                "name": "Sul Pont"
              },
              {
                "num": "11v2",
                "name": "Sul Pont"
              },
              {
                "num": "11va",
                "name": "Sul Pont"
              },
              {
                "num": "11vc",
                "name": "Sul Pont"
              },
              {
                "num": "12",
                "name": "Trem"
              },
              {
                "num": "12v1",
                "name": "Trem"
              },
              {
                "num": "12v2",
                "name": "Trem"
              },
              {
                "num": "12va",
                "name": "Trem"
              },
              {
                "num": "12vc",
                "name": "Trem"
              }
            ]
          },
          {
            "section": "Dissonants",
            "evos": [
              {
                "num": "13",
                "name": "Slow Pitch Bend"
              },
              {
                "num": "13v1",
                "name": "Slow Pitch Bend"
              },
              {
                "num": "13v2",
                "name": "Slow Pitch Bend"
              },
              {
                "num": "13va",
                "name": "Slow Pitch Bend"
              },
              {
                "num": "13vc",
                "name": "Slow Pitch Bend"
              },
              {
                "num": "14",
                "name": "Pitching Episodic"
              },
              {
                "num": "14v1",
                "name": "Pitching Episodic"
              },
              {
                "num": "14v2",
                "name": "Pitching Episodic"
              },
              {
                "num": "14va",
                "name": "Pitching Episodic"
              },
              {
                "num": "14vc",
                "name": "Pitching Episodic"
              },
              {
                "num": "15",
                "name": "Wow Effect"
              },
              {
                "num": "15v1",
                "name": "Wow Effect"
              },
              {
                "num": "15v2",
                "name": "Wow Effect"
              },
              {
                "num": "15va",
                "name": "Wow Effect"
              },
              {
                "num": "15vc",
                "name": "Wow Effect"
              },
              {
                "num": "16",
                "name": "Pont 8ve Harms"
              },
              {
                "num": "16v1",
                "name": "Pont 8ve Harms"
              },
              {
                "num": "16v2",
                "name": "Pont 8ve Harms"
              },
              {
                "num": "16va",
                "name": "Pont 8ve Harms"
              },
              {
                "num": "16vc",
                "name": "Pont 8ve Harms"
              }
            ]
          }
        ]
      },
      {
        "libName": "TND",
        "sections": [
          {
            "section": "Strings",
            "evos": [
              {
                "num": "01",
                "name": "Flautando Con Sord"
              },
              {
                "num": "01hi",
                "name": "Flautando Con Sord"
              },
              {
                "num": "01lo",
                "name": "Flautando Con Sord"
              },
              {
                "num": "02",
                "name": "Frozen"
              },
              {
                "num": "02hi",
                "name": "Frozen"
              },
              {
                "num": "02lo",
                "name": "Frozen"
              },
              {
                "num": "03",
                "name": "Gpysy"
              },
              {
                "num": "03hi",
                "name": "Gpysy"
              },
              {
                "num": "03lo",
                "name": "Gpysy"
              },
              {
                "num": "04",
                "name": "Double Stop 5th"
              },
              {
                "num": "04hi",
                "name": "Double Stop 5th"
              },
              {
                "num": "04lo",
                "name": "Double Stop 5th"
              },
              {
                "num": "05",
                "name": "Sul E"
              },
              {
                "num": "05hi",
                "name": "Sul E"
              },
              {
                "num": "05lo",
                "name": "Sul E"
              },
              {
                "num": "06",
                "name": "Air and Ice"
              },
              {
                "num": "06hi",
                "name": "Air and Ice"
              },
              {
                "num": "06lo",
                "name": "Air and Ice"
              },
              {
                "num": "07",
                "name": "Air and Ice Tratto"
              },
              {
                "num": "07hi",
                "name": "Air and Ice Tratto"
              },
              {
                "num": "07lo",
                "name": "Air and Ice Tratto"
              },
              {
                "num": "08",
                "name": "Gypsy Harmonics"
              },
              {
                "num": "08hi",
                "name": "Gypsy Harmonics"
              },
              {
                "num": "08lo",
                "name": "Gypsy Harmonics"
              },
              {
                "num": "09",
                "name": "Gypsy Harmonics Trem"
              },
              {
                "num": "09hi",
                "name": "Gypsy Harmonics Trem"
              },
              {
                "num": "09lo",
                "name": "Gypsy Harmonics Trem"
              },
              {
                "num": "10",
                "name": "Sul Pont Con Sord"
              },
              {
                "num": "10hi",
                "name": "Sul Pont Con Sord"
              },
              {
                "num": "10lo",
                "name": "Sul Pont Con Sord"
              },
              {
                "num": "11",
                "name": "Silken Con Sord"
              },
              {
                "num": "11hi",
                "name": "Silken Con Sord"
              },
              {
                "num": "11lo",
                "name": "Silken Con Sord"
              }
            ]
          },
          {
            "section": "Soft & Wild",
            "evos": [
              {
                "num": "12",
                "name": "Col Leg Tratto"
              },
              {
                "num": "12hi",
                "name": "Col Leg Tratto"
              },
              {
                "num": "12lo",
                "name": "Col Leg Tratto"
              },
              {
                "num": "13",
                "name": "Harmonic Terms"
              },
              {
                "num": "13hi",
                "name": "Harmonic Terms"
              },
              {
                "num": "13lo",
                "name": "Harmonic Terms"
              },
              {
                "num": "14",
                "name": "No Rosin"
              },
              {
                "num": "14hi",
                "name": "No Rosin"
              },
              {
                "num": "14lo",
                "name": "No Rosin"
              },
              {
                "num": "15",
                "name": "Other Harmonics"
              },
              {
                "num": "15hi",
                "name": "Other Harmonics"
              },
              {
                "num": "15lo",
                "name": "Other Harmonics"
              },
              {
                "num": "16",
                "name": "Pulsing Con Sord"
              },
              {
                "num": "16hi",
                "name": "Pulsing Con Sord"
              },
              {
                "num": "16lo",
                "name": "Pulsing Con Sord"
              },
              {
                "num": "17",
                "name": "Richochet (Vl)"
              },
              {
                "num": "18",
                "name": "Pulsing CS Sul Pont (Vc)"
              },
              {
                "num": "19",
                "name": "Sul G (vl) / Sul C (Vc)"
              },
              {
                "num": "19hi",
                "name": "Sul G (vl) / Sul C (Vc)"
              },
              {
                "num": "19lo",
                "name": "Sul G (vl) / Sul C (Vc)"
              },
              {
                "num": "20",
                "name": "Travelling Terms"
              },
              {
                "num": "20hi",
                "name": "Travelling Terms"
              },
              {
                "num": "20lo",
                "name": "Travelling Terms"
              }
            ]
          },
          {
            "section": "Woods",
            "evos": [
              {
                "num": "21",
                "name": "Air"
              },
              {
                "num": "21hi",
                "name": "Air"
              },
              {
                "num": "21lo",
                "name": "Air"
              },
              {
                "num": "22",
                "name": "Aleatoric Overblown"
              },
              {
                "num": "22hi",
                "name": "Aleatoric Overblown"
              },
              {
                "num": "22lo",
                "name": "Aleatoric Overblown"
              },
              {
                "num": "23",
                "name": "Bursts"
              },
              {
                "num": "23hi",
                "name": "Bursts"
              },
              {
                "num": "23lo",
                "name": "Bursts"
              },
              {
                "num": "24",
                "name": "Doodle tonguing"
              },
              {
                "num": "24hi",
                "name": "Doodle tonguing"
              },
              {
                "num": "24lo",
                "name": "Doodle tonguing"
              },
              {
                "num": "25",
                "name": "Finger Trills"
              },
              {
                "num": "25hi",
                "name": "Finger Trills"
              },
              {
                "num": "25lo",
                "name": "Finger Trills"
              },
              {
                "num": "26",
                "name": "Fluttered"
              },
              {
                "num": "26hi",
                "name": "Fluttered"
              },
              {
                "num": "26lo",
                "name": "Fluttered"
              },
              {
                "num": "27",
                "name": "Hollow"
              },
              {
                "num": "27hi",
                "name": "Hollow"
              },
              {
                "num": "27lo",
                "name": "Hollow"
              },
              {
                "num": "28",
                "name": "Mini Crescendo"
              },
              {
                "num": "28hi",
                "name": "Mini Crescendo"
              },
              {
                "num": "28lo",
                "name": "Mini Crescendo"
              },
              {
                "num": "29",
                "name": "Mulitphonics"
              },
              {
                "num": "29hi",
                "name": "Mulitphonics"
              },
              {
                "num": "29lo",
                "name": "Mulitphonics"
              },
              {
                "num": "30",
                "name": "Overblowing"
              },
              {
                "num": "30hi",
                "name": "Overblowing"
              },
              {
                "num": "30lo",
                "name": "Overblowing"
              },
              {
                "num": "31",
                "name": "Overblown"
              },
              {
                "num": "31hi",
                "name": "Overblown"
              },
              {
                "num": "31lo",
                "name": "Overblown"
              },
              {
                "num": "32",
                "name": "Pulsing Semicrescendo"
              },
              {
                "num": "32hi",
                "name": "Pulsing Semicrescendo"
              },
              {
                "num": "32lo",
                "name": "Pulsing Semicrescendo"
              },
              {
                "num": "33",
                "name": "Slight Bend"
              },
              {
                "num": "33hi",
                "name": "Slight Bend"
              },
              {
                "num": "33lo",
                "name": "Slight Bend"
              },
              {
                "num": "34",
                "name": "Super Air"
              },
              {
                "num": "34hi",
                "name": "Super Air"
              },
              {
                "num": "34lo",
                "name": "Super Air"
              },
              {
                "num": "35",
                "name": "Vibrato"
              },
              {
                "num": "35hi",
                "name": "Vibrato"
              },
              {
                "num": "35lo",
                "name": "Vibrato"
              }
            ]
          },
          {
            "section": "Brass",
            "evos": [
              {
                "num": "36",
                "name": "Air"
              },
              {
                "num": "36hi",
                "name": "Air"
              },
              {
                "num": "36lo",
                "name": "Air"
              },
              {
                "num": "37",
                "name": "Bursts"
              },
              {
                "num": "37hi",
                "name": "Bursts"
              },
              {
                "num": "37lo",
                "name": "Bursts"
              },
              {
                "num": "38",
                "name": "Doodle Tonguing"
              },
              {
                "num": "38hi",
                "name": "Doodle Tonguing"
              },
              {
                "num": "38lo",
                "name": "Doodle Tonguing"
              },
              {
                "num": "39",
                "name": "Doodle Tonguing mute"
              },
              {
                "num": "39hi",
                "name": "Doodle Tonguing mute"
              },
              {
                "num": "39lo",
                "name": "Doodle Tonguing mute"
              },
              {
                "num": "40",
                "name": "Finger Trills"
              },
              {
                "num": "40hi",
                "name": "Finger Trills"
              },
              {
                "num": "40lo",
                "name": "Finger Trills"
              },
              {
                "num": "41",
                "name": "Fluttered"
              },
              {
                "num": "41hi",
                "name": "Fluttered"
              },
              {
                "num": "41lo",
                "name": "Fluttered"
              },
              {
                "num": "42",
                "name": "Granular Flutter A"
              },
              {
                "num": "42hi",
                "name": "Granular Flutter A"
              },
              {
                "num": "42lo",
                "name": "Granular Flutter A"
              },
              {
                "num": "43",
                "name": "Granular Flutter B"
              },
              {
                "num": "43hi",
                "name": "Granular Flutter B"
              },
              {
                "num": "43lo",
                "name": "Granular Flutter B"
              },
              {
                "num": "44",
                "name": "Hollow"
              },
              {
                "num": "44hi",
                "name": "Hollow"
              },
              {
                "num": "44lo",
                "name": "Hollow"
              },
              {
                "num": "45",
                "name": "Mini Crescendo"
              },
              {
                "num": "45hi",
                "name": "Mini Crescendo"
              },
              {
                "num": "45lo",
                "name": "Mini Crescendo"
              },
              {
                "num": "46",
                "name": "Multiphonics"
              },
              {
                "num": "46hi",
                "name": "Multiphonics"
              },
              {
                "num": "46lo",
                "name": "Multiphonics"
              },
              {
                "num": "47",
                "name": "Overblown fff"
              },
              {
                "num": "47hi",
                "name": "Overblown fff"
              },
              {
                "num": "47lo",
                "name": "Overblown fff"
              },
              {
                "num": "48",
                "name": "Slight Bend"
              },
              {
                "num": "48hi",
                "name": "Slight Bend"
              },
              {
                "num": "48lo",
                "name": "Slight Bend"
              },
              {
                "num": "49",
                "name": "Stifled"
              },
              {
                "num": "49hi",
                "name": "Stifled"
              },
              {
                "num": "49lo",
                "name": "Stifled"
              },
              {
                "num": "50",
                "name": "Super Air"
              },
              {
                "num": "50hi",
                "name": "Super Air"
              },
              {
                "num": "50lo",
                "name": "Super Air"
              },
              {
                "num": "51",
                "name": "Vibrato"
              },
              {
                "num": "51hi",
                "name": "Vibrato"
              },
              {
                "num": "51lo",
                "name": "Vibrato"
              },
              {
                "num": "52",
                "name": "Tuning Slide Taken Off"
              }
            ]
          }
        ]
      },
      {
        "libName": "FS",
        "sections": [
          {
            "section": "Traditional",
            "evos": [
              {
                "num": "01",
                "name": "Fragile — delicate"
              },
              {
                "num": "02",
                "name": "Gossamer — full bodied"
              },
              {
                "num": "03",
                "name": "Fragile muted — delicate"
              },
              {
                "num": "04",
                "name": "Gossamer muted — full bodied"
              },
              {
                "num": "05",
                "name": "Delicate — full and light"
              },
              {
                "num": "06",
                "name": "Gossamer — full"
              },
              {
                "num": "07",
                "name": "Delicate muted — full"
              },
              {
                "num": "08",
                "name": "Gossamer muted — full"
              },
              {
                "num": "09",
                "name": "Delicate — beautiful siren"
              },
              {
                "num": "10",
                "name": "Circular fragile — delicate"
              },
              {
                "num": "11",
                "name": "Muted fragile — accelerating siren"
              },
              {
                "num": "12",
                "name": "Fragile — beautiful siren"
              },
              {
                "num": "13",
                "name": "Pure fragility — overtones"
              },
              {
                "num": "14",
                "name": "Muted fragile — beautiful siren"
              },
              {
                "num": "15",
                "name": "Fragile — errors"
              },
              {
                "num": "16",
                "name": "Delicate — shimmer"
              },
              {
                "num": "17",
                "name": "Gossamer — pulsing shimmer"
              },
              {
                "num": "18",
                "name": "Delicate muted — shimmer"
              },
              {
                "num": "19",
                "name": "Gossamer muted — strong shimmer"
              },
              {
                "num": "20",
                "name": "Delicate — bow pulse"
              },
              {
                "num": "21",
                "name": "Gossamer — bow pulse"
              },
              {
                "num": "22",
                "name": "Delicate muted — bow pulse"
              },
              {
                "num": "23",
                "name": "Gossamer muted — bow pulse"
              },
              {
                "num": "24",
                "name": "Pure fragility — almost niente"
              }
            ]
          },
          {
            "section": "Episodic",
            "evos": [
              {
                "num": "25",
                "name": "Straight — harmonic wisps"
              },
              {
                "num": "26",
                "name": "Straight — overtone wisps"
              },
              {
                "num": "27",
                "name": "Straight muted — overtone wisps"
              },
              {
                "num": "28",
                "name": "Warm — shudders"
              },
              {
                "num": "29",
                "name": "Warm — overtone shudders"
              },
              {
                "num": "30",
                "name": "Straight — semitone flickers"
              },
              {
                "num": "31",
                "name": "Warm — tone flickers"
              },
              {
                "num": "32",
                "name": "Warm — ricochet flickers"
              },
              {
                "num": "33",
                "name": "Straight — ricochet flickers"
              }
            ]
          },
          {
            "section": "Extreme",
            "evos": [
              {
                "num": "34",
                "name": "Warm shimmer — heavy overtones"
              },
              {
                "num": "35",
                "name": "Fragile — full overtones"
              },
              {
                "num": "36",
                "name": "Warm siren — overtone siren"
              },
              {
                "num": "37",
                "name": "Bend — gliss"
              },
              {
                "num": "38",
                "name": "Light pulse — heavy pulse"
              },
              {
                "num": "39",
                "name": "Straight — heavy ricochet"
              },
              {
                "num": "40",
                "name": "Straight — strong madness"
              },
              {
                "num": "41",
                "name": "Straight — strong madness (with pedal)"
              },
              {
                "num": "42",
                "name": "Straight — reversed madness"
              },
              {
                "num": "43",
                "name": "Straight — reversed madness (with pedal)"
              },
              {
                "num": "44",
                "name": "Warm — heavy and wide"
              },
              {
                "num": "45",
                "name": "Warm — heavy bending pulse"
              },
              {
                "num": "46",
                "name": "Warm — heavy ricochet pulse"
              },
              {
                "num": "47",
                "name": "Warm — siren — strong madness"
              },
              {
                "num": "48",
                "name": "Warm — heavy ricochet — strong madness"
              }
            ]
          }
        ]
      },
      {
        "libName": "CFS",
        "sections": [
          {
            "section": "Longs",
            "evos": [
              {
                "num": "01",
                "name": "Long Flautando CS"
              },
              {
                "num": "02",
                "name": "Long Super Sul Tasto"
              },
              {
                "num": "03",
                "name": "Long Col Legno Tratto"
              },
              {
                "num": "04",
                "name": "Long Sul Tasto > Sul Pont"
              },
              {
                "num": "05",
                "name": "Long Sul Tasto > Flautando"
              },
              {
                "num": "06",
                "name": "Long No Rosin"
              },
              {
                "num": "07",
                "name": "Tremolo CS Sul Pont"
              },
              {
                "num": "08",
                "name": "Harmonic Tremolo"
              }
            ]
          }
        ]
      },
      {
        "libName": "IS",
        "sections": [
          {
            "section": "Longs",
            "evos": [
              {
                "num": "01",
                "name": "Longs"
              },
              {
                "num": "02",
                "name": "Longs CS"
              },
              {
                "num": "03",
                "name": "Longs Half Section"
              },
              {
                "num": "04",
                "name": "Flautando"
              },
              {
                "num": "05",
                "name": "Harmonics"
              }
            ]
          }
        ]
      },
      {
        "libName": "OS",
        "sections": [
          {
            "section": "Strings High",
            "evos": [
              {
                "num": "01",
                "name": "Tenuto swarm (0%)"
              },
              {
                "num": "01a",
                "name": "Tenuto swarm (100%)"
              },
              {
                "num": "02",
                "name": "Tenuto CS tasto swarm (0%)"
              },
              {
                "num": "02a",
                "name": "Tenuto CS tasto swarm (0%)"
              },
              {
                "num": "03",
                "name": "Short swarm 1 (0%)"
              },
              {
                "num": "03a",
                "name": "Short swarm 1 (100%)"
              },
              {
                "num": "04",
                "name": "Short swarm 2 (0%)"
              },
              {
                "num": "04a",
                "name": "Short swarm 2 (100 %)"
              },
              {
                "num": "05",
                "name": "Flautando swarm"
              },
              {
                "num": "06",
                "name": "Spiccato molto sul pont*"
              },
              {
                "num": "07",
                "name": "Short swarm harmonics"
              },
              {
                "num": "08",
                "name": "Whisper swarm"
              },
              {
                "num": "09",
                "name": "Pizz Swarm (0%)"
              },
              {
                "num": "09a",
                "name": "Pizz Swarm (100%)"
              },
              {
                "num": "10",
                "name": "Combo swarm"
              },
              {
                "num": "11",
                "name": "Combo swell"
              }
            ]
          },
          {
            "section": "Strings Low",
            "evos": [
              {
                "num": "12",
                "name": "Tenuto swarm (0%)"
              },
              {
                "num": "12a",
                "name": "Tenuto swarm (100%)"
              },
              {
                "num": "13",
                "name": "Tenuto CS tasto swarm (0%)"
              },
              {
                "num": "13a",
                "name": "Tenuto CS tasto swarm (100%)"
              },
              {
                "num": "14",
                "name": "Short swarm 1 (0%)"
              },
              {
                "num": "14a",
                "name": "Short swarm 1 (100%)"
              },
              {
                "num": "15",
                "name": "Short swarm 2 (0%)"
              },
              {
                "num": "15a",
                "name": "Short swarm 2 (100%)"
              },
              {
                "num": "16",
                "name": "Tenuto sparse"
              },
              {
                "num": "17",
                "name": "Spiccato sul pont (0%)"
              },
              {
                "num": "17a",
                "name": "Spiccato sul pont (100%)"
              },
              {
                "num": "18",
                "name": "Short swarm harmonics"
              },
              {
                "num": "19",
                "name": "Whisper swarm"
              },
              {
                "num": "20",
                "name": "Pizzicato swarm (0%)"
              },
              {
                "num": "20a",
                "name": "Pizzicato swarm (100%)"
              },
              {
                "num": "21",
                "name": "Tenuto trem swarm (0%)"
              },
              {
                "num": "21a",
                "name": "Tenuto trem swarm 100%)"
              },
              {
                "num": "22",
                "name": "combo swarm"
              },
              {
                "num": "23",
                "name": "combo swell"
              }
            ]
          },
          {
            "section": "Woods high",
            "evos": [
              {
                "num": "24",
                "name": "Tenuto swarm 1 (0%)"
              },
              {
                "num": "24a",
                "name": "Tenuto swarm 1 (100%)"
              },
              {
                "num": "25",
                "name": "Tenuto swarm 2 (0%)"
              },
              {
                "num": "25a",
                "name": "Tenuto swarm 2 (100%)"
              },
              {
                "num": "26",
                "name": "Staccatissimo swarm"
              },
              {
                "num": "27",
                "name": "Tongued swarm"
              },
              {
                "num": "28",
                "name": "Flutter swarm (0%)"
              },
              {
                "num": "28a",
                "name": "Flutter swarm (100%)"
              },
              {
                "num": "29",
                "name": "Air swarm"
              },
              {
                "num": "30",
                "name": "Clari whisper swarm"
              },
              {
                "num": "31",
                "name": "Flute whisper swarm"
              },
              {
                "num": "32",
                "name": "Combo swarm"
              },
              {
                "num": "33",
                "name": "Upper mordent swarm semitone"
              },
              {
                "num": "34",
                "name": "Upper mordent swarm wholetone"
              }
            ]
          },
          {
            "section": "Winds Low",
            "evos": [
              {
                "num": "35",
                "name": "Tenuto swarm (0%)"
              },
              {
                "num": "35a",
                "name": "Tenuto swarm (100%)"
              },
              {
                "num": "36",
                "name": "Flutter swarm"
              },
              {
                "num": "37",
                "name": "Tongued swarm"
              },
              {
                "num": "38",
                "name": "Sparse swarm stacc"
              },
              {
                "num": "39",
                "name": "Combo swarm"
              },
              {
                "num": "40",
                "name": "Bassoon pop"
              },
              {
                "num": "41",
                "name": "Slap staccatissimo"
              }
            ]
          },
          {
            "section": "Horns",
            "evos": [
              {
                "num": "42",
                "name": "Tenuto swarm (0%)"
              },
              {
                "num": "42a",
                "name": "Tenuto swarm (100%)"
              },
              {
                "num": "43",
                "name": "Muted swarm"
              },
              {
                "num": "44",
                "name": "Staccatissimo swarm (0%)"
              },
              {
                "num": "44a",
                "name": "Staccatissimo swarm (100%)"
              },
              {
                "num": "45",
                "name": "Flutter swarm"
              },
              {
                "num": "46",
                "name": "Tonguing swarm"
              },
              {
                "num": "47",
                "name": "Upper mordent swarm semitone"
              },
              {
                "num": "48",
                "name": "Upper mordent swarm wholetone"
              }
            ]
          },
          {
            "section": "Bones",
            "evos": [
              {
                "num": "49",
                "name": "Tenuto Swarm (0%)"
              },
              {
                "num": "49a",
                "name": "Tenuto Swarm (100%)"
              },
              {
                "num": "50",
                "name": "Muted swarm"
              },
              {
                "num": "51",
                "name": "Staccatissimo swarm"
              },
              {
                "num": "52",
                "name": "Flutter swarm"
              },
              {
                "num": "53",
                "name": "Tongued swarm"
              },
              {
                "num": "54",
                "name": "Upper mordent swarm semitone"
              },
              {
                "num": "55",
                "name": "Upper mordent swarm wholetone"
              }
            ]
          },
          {
            "section": "Brass",
            "evos": [
              {
                "num": "56",
                "name": "Swells cresc"
              },
              {
                "num": "57",
                "name": "Swells dim"
              }
            ]
          }
        ]
      },
      {
        "libName": "EWC",
        "sections": [
          {
            "section": "Simple",
            "evos": [
              {
                "num": "01",
                "name": "Long AAH"
              },
              {
                "num": "02",
                "name": "Long MMM"
              },
              {
                "num": "03",
                "name": "Long OOH"
              }
            ]
          },
          {
            "section": "Dynamic",
            "evos": [
              {
                "num": "04",
                "name": "Dynamic Swell AAH"
              },
              {
                "num": "05",
                "name": "Dynamic Swell MMM"
              },
              {
                "num": "06",
                "name": "Dynamic Swell OOH"
              },
              {
                "num": "07",
                "name": "Dynamic Swell EEH"
              }
            ]
          },
          {
            "section": "Episodic",
            "evos": [
              {
                "num": "08",
                "name": "Episodic Evo AAH DAH"
              },
              {
                "num": "09",
                "name": "Episodic Evo AAH NAH"
              },
              {
                "num": "10",
                "name": "Episodic Evo MMM HYAH"
              },
              {
                "num": "11",
                "name": "Episodic Evo OOH NAH"
              }
            ]
          },
          {
            "section": "Clashes",
            "evos": [
              {
                "num": "12",
                "name": "Long AAH (min 2nd)"
              },
              {
                "num": "13",
                "name": "Long AAH (maj 2nd)"
              },
              {
                "num": "14",
                "name": "Long Static AAH (min 2nd)"
              },
              {
                "num": "15",
                "name": "Long Static AAH (maj 2nd)"
              },
              {
                "num": "16",
                "name": "Long Pitch Clash AAH (min 3rd)"
              },
              {
                "num": "17",
                "name": "Long Pitch Clash AAH (maj 3rd)"
              },
              {
                "num": "18",
                "name": "Long Static AAH (min 3rd)"
              },
              {
                "num": "19",
                "name": "Long Static AAH (maj 3rd)"
              },
              {
                "num": "20",
                "name": "Long AAH (perf 4)"
              },
              {
                "num": "21",
                "name": "Long AAH (perf 5)"
              },
              {
                "num": "22",
                "name": "Long Evo Cloud Grace AAH (min 2)"
              },
              {
                "num": "23",
                "name": "Long Evo Cloud Grace AAH (maj 2)"
              }
            ]
          },
          {
            "section": "Simple 2",
            "evos": [
              {
                "num": "24",
                "name": "Long Evo AAH EH AAH"
              },
              {
                "num": "25",
                "name": "Long Evo AAH Full out"
              },
              {
                "num": "26",
                "name": "Lone Evo EH"
              },
              {
                "num": "27",
                "name": "Long Evo OOH AAH OOH"
              },
              {
                "num": "28",
                "name": "Long Evo MMM AH MMM"
              },
              {
                "num": "29",
                "name": "Long Evo Breathy MMM"
              }
            ]
          },
          {
            "section": "Rhythmmic",
            "evos": [
              {
                "num": "30",
                "name": "Long Rhythmic Evo DEE"
              },
              {
                "num": "31",
                "name": "Long Rhythmic Evo Lah"
              },
              {
                "num": "32",
                "name": "Long Rhythmic Evo NAH"
              }
            ]
          },
          {
            "section": "Episotic 2",
            "evos": [
              {
                "num": "33",
                "name": "Long Episodic non vib to vib AAH"
              },
              {
                "num": "34",
                "name": "Long Episodic non vib to vib OOH"
              },
              {
                "num": "35",
                "name": "Long Episodic non vib to vib MMM"
              }
            ]
          },
          {
            "section": "Clashes 2",
            "evos": [
              {
                "num": "36",
                "name": "Long Evo microtonal bending AAH"
              },
              {
                "num": "37",
                "name": "Long Evo oscillating AAH"
              },
              {
                "num": "38",
                "name": "Long Evo Microtonal shift"
              },
              {
                "num": "39",
                "name": "Long Evo microtonal clusters AAH"
              }
            ]
          },
          {
            "section": "Special",
            "evos": [
              {
                "num": "40",
                "name": "Long Evo Breathy OOH"
              },
              {
                "num": "41",
                "name": "Long Evo Fluctuating OOH"
              },
              {
                "num": "42",
                "name": "Long Evo FP"
              },
              {
                "num": "43",
                "name": "Long Evo Erupting HMMs"
              },
              {
                "num": "44",
                "name": "Long Evo Riochot AAH NGH"
              },
              {
                "num": "45",
                "name": "Long Evo Aleatoric AAH"
              },
              {
                "num": "46",
                "name": "Long Evo Bubbling"
              },
              {
                "num": "47",
                "name": "Long Evo Harmonic"
              },
              {
                "num": "48",
                "name": "Long Evo NZ"
              },
              {
                "num": "49",
                "name": "Long Evo Cyclical"
              },
              {
                "num": "50",
                "name": "Long Evo Whistle HMM"
              },
              {
                "num": "51",
                "name": "Long Evo Pitched Air"
              }
            ]
          }
        ]
      },
      {
        "libName": "LCOT",
        "sections": [
          {
            "section": "Ethereal",
            "evos": [
              {
                "num": "01",
                "name": "First Light"
              },
              {
                "num": "02",
                "name": "Etherial Tail"
              },
              {
                "num": "03",
                "name": "Frozen Overtones"
              },
              {
                "num": "04",
                "name": "Space Chatter"
              },
              {
                "num": "05",
                "name": "Aurora Borealis"
              },
              {
                "num": "06",
                "name": "Celestial Sand"
              },
              {
                "num": "07",
                "name": "Cosmic Refraction"
              },
              {
                "num": "08",
                "name": "Vapour Undulation"
              }
            ]
          },
          {
            "section": "Mercurial",
            "evos": [
              {
                "num": "09",
                "name": "Infinity Shudder"
              },
              {
                "num": "10",
                "name": "Fractured Air"
              },
              {
                "num": "11",
                "name": "Chrome Ocean"
              },
              {
                "num": "12",
                "name": "Gravitational Lens"
              },
              {
                "num": "13",
                "name": "Primordial Soup"
              },
              {
                "num": "14",
                "name": "Bantam Supernovae"
              },
              {
                "num": "15",
                "name": "Effervescent Arc"
              },
              {
                "num": "16",
                "name": "Frozen Light"
              }
            ]
          },
          {
            "section": "Quantum",
            "evos": [
              {
                "num": "17",
                "name": "Space Revereberation"
              },
              {
                "num": "18",
                "name": "Gravity Pillow"
              },
              {
                "num": "19",
                "name": "Subatomic Magnet"
              },
              {
                "num": "20",
                "name": "Onyx Wave"
              },
              {
                "num": "21",
                "name": "Drone Distortion"
              },
              {
                "num": "22",
                "name": "Octave Bloom"
              },
              {
                "num": "23",
                "name": "Space forest"
              },
              {
                "num": "24",
                "name": "Dirtortion Halo"
              }
            ]
          },
          {
            "section": "Astral",
            "evos": [
              {
                "num": "25",
                "name": "Glass Winds"
              },
              {
                "num": "26",
                "name": "Glass Bloom"
              },
              {
                "num": "27",
                "name": "Microtonal Interference"
              },
              {
                "num": "28",
                "name": "Frozen Waves"
              },
              {
                "num": "29",
                "name": "Whisper Trills"
              },
              {
                "num": "30",
                "name": "Still Air"
              },
              {
                "num": "31",
                "name": "Tremor Drones"
              },
              {
                "num": "32",
                "name": "Glass Quiver"
              }
            ]
          },
          {
            "section": "Var Etherial",
            "evos": [
              {
                "num": "01a",
                "name": "Ethereal Tail (MW)"
              },
              {
                "num": "02a",
                "name": "Ethereal Tail (Vel)"
              },
              {
                "num": "03a",
                "name": "First Light"
              },
              {
                "num": "04a",
                "name": "Ethereal Tail (MW)"
              },
              {
                "num": "05a",
                "name": "Ethereal Tail (Vel)"
              },
              {
                "num": "06a",
                "name": "Shimmer Oscillations"
              },
              {
                "num": "07a",
                "name": "Frozen Overtones"
              },
              {
                "num": "08a",
                "name": "Space Chatter"
              },
              {
                "num": "09a",
                "name": "Aurora Borealis"
              },
              {
                "num": "10a",
                "name": "Celestial Sand"
              },
              {
                "num": "11a",
                "name": "Cosmic Refraction"
              },
              {
                "num": "12a",
                "name": "Vapour Undulation"
              }
            ]
          },
          {
            "section": "Var Mercurial",
            "evos": [
              {
                "num": "13a",
                "name": "Mercurial Tail (MW)"
              },
              {
                "num": "14a",
                "name": "Mercurial Tail (Vel)"
              },
              {
                "num": "15a",
                "name": "Mercurial Tail (MW)"
              },
              {
                "num": "16a",
                "name": "Mercurial Tail (Vel)"
              },
              {
                "num": "17a",
                "name": "Infinity Shudder"
              },
              {
                "num": "18a",
                "name": "Fractured Air"
              },
              {
                "num": "19a",
                "name": "Chrome Ocean"
              },
              {
                "num": "20a",
                "name": "Gravitational Lens"
              },
              {
                "num": "21a",
                "name": "Primordial Soup"
              },
              {
                "num": "22a",
                "name": "Bantam Supernovae"
              },
              {
                "num": "23a",
                "name": "Effervescent Arc"
              },
              {
                "num": "24a",
                "name": "Frozen Light"
              }
            ]
          },
          {
            "section": "Var Quantum",
            "evos": [
              {
                "num": "25a",
                "name": "Quantum Tail (MW)"
              },
              {
                "num": "26a",
                "name": "Quantum Tail (Vel)"
              },
              {
                "num": "27a",
                "name": "Quantum Tail (MW)"
              },
              {
                "num": "28a",
                "name": "Quantum Tail (Vel)"
              },
              {
                "num": "29a",
                "name": "Space Reverberation"
              },
              {
                "num": "30a",
                "name": "Gravity Pillow"
              },
              {
                "num": "31a",
                "name": "Subatomic Magnet"
              },
              {
                "num": "32a",
                "name": "Onyx Wave"
              }
            ]
          },
          {
            "section": "Texture Grid",
            "evos": [
              {
                "num": "TG01",
                "name": "abandoned voices"
              },
              {
                "num": "TG02",
                "name": "aurora rhodes"
              },
              {
                "num": "TG03",
                "name": "cathedral walls"
              },
              {
                "num": "TG04",
                "name": "chrome surfer"
              },
              {
                "num": "TG05",
                "name": "close but far"
              },
              {
                "num": "TG06",
                "name": "cosmic reflector"
              },
              {
                "num": "TG07",
                "name": "decompression"
              },
              {
                "num": "TG08",
                "name": "deep space"
              },
              {
                "num": "TG09",
                "name": "depths of valhalla"
              },
              {
                "num": "TG10",
                "name": "earthrise"
              },
              {
                "num": "TG11",
                "name": "electric swarms"
              },
              {
                "num": "TG12",
                "name": "feedback drone 1"
              },
              {
                "num": "TG13",
                "name": "feedback drone 2"
              },
              {
                "num": "TG14",
                "name": "night organ"
              },
              {
                "num": "TG15",
                "name": "out of reach"
              },
              {
                "num": "TG16",
                "name": "piano whisper"
              },
              {
                "num": "TG17",
                "name": "plume delays"
              },
              {
                "num": "TG18",
                "name": "probe scanners"
              },
              {
                "num": "TG19",
                "name": "rattle pad"
              },
              {
                "num": "TG20",
                "name": "room resonance"
              },
              {
                "num": "TG21",
                "name": "scratchy swell pad"
              },
              {
                "num": "TG22",
                "name": "short crystal shards 1"
              },
              {
                "num": "TG23",
                "name": "short crystal shards 2"
              },
              {
                "num": "TG24",
                "name": "storm creaks"
              },
              {
                "num": "TG25",
                "name": "string theory"
              },
              {
                "num": "TG26",
                "name": "stutter roll"
              },
              {
                "num": "TG27",
                "name": "synthesized reality"
              },
              {
                "num": "TG28",
                "name": "the aether"
              },
              {
                "num": "TG29",
                "name": "vocal airs"
              },
              {
                "num": "TG30",
                "name": "wormholes"
              }
            ]
          },
          {
            "section": "Drones",
            "evos": [
              {
                "num": "D01",
                "name": "Aurora borealis"
              },
              {
                "num": "D02",
                "name": "Cosmic refraction"
              },
              {
                "num": "D03",
                "name": "Distortion halo"
              },
              {
                "num": "D04",
                "name": "Ethereal tail (Vel)"
              },
              {
                "num": "D05",
                "name": "Fractured air"
              },
              {
                "num": "D06",
                "name": "Frozen light"
              },
              {
                "num": "D07",
                "name": "Frozen waves"
              },
              {
                "num": "D08",
                "name": "Glass bloom"
              },
              {
                "num": "D09",
                "name": "Glass winds"
              },
              {
                "num": "D10",
                "name": "Octave bloom"
              },
              {
                "num": "D11",
                "name": "Shimmer oscillations"
              },
              {
                "num": "D12",
                "name": "Space forest"
              },
              {
                "num": "D13",
                "name": "Space reverberation"
              },
              {
                "num": "D14",
                "name": "Still air"
              },
              {
                "num": "D15",
                "name": "Whisper Trills"
              }
            ]
          },
          {
            "section": "Mindcloud",
            "evos": [
              {
                "num": "M01",
                "name": "Microtonal mindcloud A1"
              },
              {
                "num": "M02",
                "name": "Microtonal mindcloud A2"
              },
              {
                "num": "M03",
                "name": "Microtonal mindcloud B1"
              },
              {
                "num": "M04",
                "name": "Microtonal mindcloud B2"
              }
            ]
          }
        ]
      }
    ]
  },
  "audio": {
    "demo1": {
      "75": {
        "OACE": [
          {
            "num": "10",
            "audioPath": "demo1/bpm75/OACE 10_1.mp3"
          },
          {
            "num": "11",
            "audioPath": "demo1/bpm75/OACE 11_1.mp3"
          },
          {
            "num": "12",
            "audioPath": "demo1/bpm75/OACE 12_1.mp3"
          },
          {
            "num": "13",
            "audioPath": "demo1/bpm75/OACE 13_1.mp3"
          },
          {
            "num": "14",
            "audioPath": "demo1/bpm75/OACE 14_1.mp3"
          },
          {
            "num": "15",
            "audioPath": "demo1/bpm75/OACE 15_1.mp3"
          },
          {
            "num": "16",
            "audioPath": "demo1/bpm75/OACE 16_1.mp3"
          },
          {
            "num": "17",
            "audioPath": "demo1/bpm75/OACE 17_1.mp3"
          },
          {
            "num": "18",
            "audioPath": "demo1/bpm75/OACE 18_1.mp3"
          },
          {
            "num": "19",
            "audioPath": "demo1/bpm75/OACE 19_1.mp3"
          },
          {
            "num": "01",
            "audioPath": "demo1/bpm75/OACE 1_1.mp3"
          },
          {
            "num": "02",
            "audioPath": "demo1/bpm75/OACE 2_1.mp3"
          },
          {
            "num": "03",
            "audioPath": "demo1/bpm75/OACE 3_1.mp3"
          },
          {
            "num": "04",
            "audioPath": "demo1/bpm75/OACE 4_1.mp3"
          },
          {
            "num": "05",
            "audioPath": "demo1/bpm75/OACE 5_1.mp3"
          },
          {
            "num": "06",
            "audioPath": "demo1/bpm75/OACE 6_1.mp3"
          },
          {
            "num": "07",
            "audioPath": "demo1/bpm75/OACE 7_1.mp3"
          },
          {
            "num": "08",
            "audioPath": "demo1/bpm75/OACE 8_1.mp3"
          },
          {
            "num": "09",
            "audioPath": "demo1/bpm75/OACE 9_1.mp3"
          },
          {
            "num": "W01",
            "audioPath": "demo1/bpm75/OACE W01 Waves_1.mp3"
          },
          {
            "num": "W02",
            "audioPath": "demo1/bpm75/OACE W02 Waves vib_1.mp3"
          },
          {
            "num": "W03",
            "audioPath": "demo1/bpm75/OACE W03 Waves Trem_1.mp3"
          }
        ],
        "LCOT": [
          {
            "num": "D01",
            "audioPath": "demo1/bpm75/LCOT  D01_1.mp3"
          },
          {
            "num": "D02",
            "audioPath": "demo1/bpm75/LCOT  D02_1.mp3"
          },
          {
            "num": "D03",
            "audioPath": "demo1/bpm75/LCOT  D03_1.mp3"
          },
          {
            "num": "D04",
            "audioPath": "demo1/bpm75/LCOT  D04_1.mp3"
          },
          {
            "num": "D05",
            "audioPath": "demo1/bpm75/LCOT  D05_1.mp3"
          },
          {
            "num": "D06",
            "audioPath": "demo1/bpm75/LCOT  D06_1.mp3"
          },
          {
            "num": "D07",
            "audioPath": "demo1/bpm75/LCOT  D07_1.mp3"
          },
          {
            "num": "D08",
            "audioPath": "demo1/bpm75/LCOT  D08_1.mp3"
          },
          {
            "num": "D09",
            "audioPath": "demo1/bpm75/LCOT  D09_1.mp3"
          },
          {
            "num": "D10",
            "audioPath": "demo1/bpm75/LCOT  D10_1.mp3"
          },
          {
            "num": "D11",
            "audioPath": "demo1/bpm75/LCOT  D11_1.mp3"
          },
          {
            "num": "D12",
            "audioPath": "demo1/bpm75/LCOT  D12_1.mp3"
          },
          {
            "num": "D13",
            "audioPath": "demo1/bpm75/LCOT  D13_1.mp3"
          },
          {
            "num": "D14",
            "audioPath": "demo1/bpm75/LCOT  D14_1.mp3"
          },
          {
            "num": "D15",
            "audioPath": "demo1/bpm75/LCOT  D15_1.mp3"
          },
          {
            "num": "M01",
            "audioPath": "demo1/bpm75/LCOT  M01_1.mp3"
          },
          {
            "num": "M02",
            "audioPath": "demo1/bpm75/LCOT  M02_1.mp3"
          },
          {
            "num": "M03",
            "audioPath": "demo1/bpm75/LCOT  M03_1.mp3"
          },
          {
            "num": "M04",
            "audioPath": "demo1/bpm75/LCOT  M04_1.mp3"
          },
          {
            "num": "01a",
            "audioPath": "demo1/bpm75/LCOT 01a_1.mp3"
          },
          {
            "num": "02a",
            "audioPath": "demo1/bpm75/LCOT 02a_1.mp3"
          },
          {
            "num": "03a",
            "audioPath": "demo1/bpm75/LCOT 03a_1.mp3"
          },
          {
            "num": "04a",
            "audioPath": "demo1/bpm75/LCOT 04a_1.mp3"
          },
          {
            "num": "05a",
            "audioPath": "demo1/bpm75/LCOT 05a_1.mp3"
          },
          {
            "num": "06a",
            "audioPath": "demo1/bpm75/LCOT 06a_1.mp3"
          },
          {
            "num": "07a",
            "audioPath": "demo1/bpm75/LCOT 07a_1.mp3"
          },
          {
            "num": "08a",
            "audioPath": "demo1/bpm75/LCOT 08a_1.mp3"
          },
          {
            "num": "09a",
            "audioPath": "demo1/bpm75/LCOT 09a_1.mp3"
          },
          {
            "num": "10",
            "audioPath": "demo1/bpm75/LCOT 10_1.mp3"
          },
          {
            "num": "10a",
            "audioPath": "demo1/bpm75/LCOT 10a_1.mp3"
          },
          {
            "num": "11",
            "audioPath": "demo1/bpm75/LCOT 11_1.mp3"
          },
          {
            "num": "11a",
            "audioPath": "demo1/bpm75/LCOT 11a_1.mp3"
          },
          {
            "num": "12",
            "audioPath": "demo1/bpm75/LCOT 12_1.mp3"
          },
          {
            "num": "12a",
            "audioPath": "demo1/bpm75/LCOT 12a_1.mp3"
          },
          {
            "num": "13",
            "audioPath": "demo1/bpm75/LCOT 13_1.mp3"
          },
          {
            "num": "13a",
            "audioPath": "demo1/bpm75/LCOT 13a_1.mp3"
          },
          {
            "num": "14",
            "audioPath": "demo1/bpm75/LCOT 14_1.mp3"
          },
          {
            "num": "14a",
            "audioPath": "demo1/bpm75/LCOT 14a_1.mp3"
          },
          {
            "num": "15",
            "audioPath": "demo1/bpm75/LCOT 15_1.mp3"
          },
          {
            "num": "15a",
            "audioPath": "demo1/bpm75/LCOT 15a_1.mp3"
          },
          {
            "num": "16",
            "audioPath": "demo1/bpm75/LCOT 16_1.mp3"
          },
          {
            "num": "16a",
            "audioPath": "demo1/bpm75/LCOT 16a_1.mp3"
          },
          {
            "num": "17",
            "audioPath": "demo1/bpm75/LCOT 17_1.mp3"
          },
          {
            "num": "17a",
            "audioPath": "demo1/bpm75/LCOT 17a_1.mp3"
          },
          {
            "num": "18",
            "audioPath": "demo1/bpm75/LCOT 18_1.mp3"
          },
          {
            "num": "18a",
            "audioPath": "demo1/bpm75/LCOT 18a_1.mp3"
          },
          {
            "num": "19",
            "audioPath": "demo1/bpm75/LCOT 19_1.mp3"
          },
          {
            "num": "19a",
            "audioPath": "demo1/bpm75/LCOT 19a_1.mp3"
          },
          {
            "num": "01",
            "audioPath": "demo1/bpm75/LCOT 1_1.mp3"
          },
          {
            "num": "20",
            "audioPath": "demo1/bpm75/LCOT 20_1.mp3"
          },
          {
            "num": "20a",
            "audioPath": "demo1/bpm75/LCOT 20a_1.mp3"
          },
          {
            "num": "21",
            "audioPath": "demo1/bpm75/LCOT 21_1.mp3"
          },
          {
            "num": "21a",
            "audioPath": "demo1/bpm75/LCOT 21a_1.mp3"
          },
          {
            "num": "22",
            "audioPath": "demo1/bpm75/LCOT 22_1.mp3"
          },
          {
            "num": "22a",
            "audioPath": "demo1/bpm75/LCOT 22a_1.mp3"
          },
          {
            "num": "23",
            "audioPath": "demo1/bpm75/LCOT 23_1.mp3"
          },
          {
            "num": "23a",
            "audioPath": "demo1/bpm75/LCOT 23a_1.mp3"
          },
          {
            "num": "24",
            "audioPath": "demo1/bpm75/LCOT 24_1.mp3"
          },
          {
            "num": "24a",
            "audioPath": "demo1/bpm75/LCOT 24a_1.mp3"
          },
          {
            "num": "25",
            "audioPath": "demo1/bpm75/LCOT 25_1.mp3"
          },
          {
            "num": "25a",
            "audioPath": "demo1/bpm75/LCOT 25a_1.mp3"
          },
          {
            "num": "26",
            "audioPath": "demo1/bpm75/LCOT 26_1.mp3"
          },
          {
            "num": "26a",
            "audioPath": "demo1/bpm75/LCOT 26a_1.mp3"
          },
          {
            "num": "27",
            "audioPath": "demo1/bpm75/LCOT 27_1.mp3"
          },
          {
            "num": "27a",
            "audioPath": "demo1/bpm75/LCOT 27a_1.mp3"
          },
          {
            "num": "28",
            "audioPath": "demo1/bpm75/LCOT 28_1.mp3"
          },
          {
            "num": "28a",
            "audioPath": "demo1/bpm75/LCOT 28a_1.mp3"
          },
          {
            "num": "29",
            "audioPath": "demo1/bpm75/LCOT 29_1.mp3"
          },
          {
            "num": "29a",
            "audioPath": "demo1/bpm75/LCOT 29a_1.mp3"
          },
          {
            "num": "02",
            "audioPath": "demo1/bpm75/LCOT 2_1.mp3"
          },
          {
            "num": "30",
            "audioPath": "demo1/bpm75/LCOT 30_1.mp3"
          },
          {
            "num": "30a",
            "audioPath": "demo1/bpm75/LCOT 30a_1.mp3"
          },
          {
            "num": "31",
            "audioPath": "demo1/bpm75/LCOT 31_1.mp3"
          },
          {
            "num": "31a",
            "audioPath": "demo1/bpm75/LCOT 31a_1.mp3"
          },
          {
            "num": "32",
            "audioPath": "demo1/bpm75/LCOT 32_1.mp3"
          },
          {
            "num": "32a",
            "audioPath": "demo1/bpm75/LCOT 32a_1.mp3"
          },
          {
            "num": "03",
            "audioPath": "demo1/bpm75/LCOT 3_1.mp3"
          },
          {
            "num": "04",
            "audioPath": "demo1/bpm75/LCOT 4_1.mp3"
          },
          {
            "num": "05",
            "audioPath": "demo1/bpm75/LCOT 5_1.mp3"
          },
          {
            "num": "06",
            "audioPath": "demo1/bpm75/LCOT 6_1.mp3"
          },
          {
            "num": "07",
            "audioPath": "demo1/bpm75/LCOT 7_1.mp3"
          },
          {
            "num": "08",
            "audioPath": "demo1/bpm75/LCOT 8_1.mp3"
          },
          {
            "num": "09",
            "audioPath": "demo1/bpm75/LCOT 9_1.mp3"
          },
          {
            "num": "TG01",
            "audioPath": "demo1/bpm75/LCOT TG01_1.mp3"
          },
          {
            "num": "TG02",
            "audioPath": "demo1/bpm75/LCOT TG02_1.mp3"
          },
          {
            "num": "TG03",
            "audioPath": "demo1/bpm75/LCOT TG03_1.mp3"
          },
          {
            "num": "TG04",
            "audioPath": "demo1/bpm75/LCOT TG04_1.mp3"
          },
          {
            "num": "TG05",
            "audioPath": "demo1/bpm75/LCOT TG05_1.mp3"
          },
          {
            "num": "TG06",
            "audioPath": "demo1/bpm75/LCOT TG06_1.mp3"
          },
          {
            "num": "TG07",
            "audioPath": "demo1/bpm75/LCOT TG07_1.mp3"
          },
          {
            "num": "TG08",
            "audioPath": "demo1/bpm75/LCOT TG08_1.mp3"
          },
          {
            "num": "TG09",
            "audioPath": "demo1/bpm75/LCOT TG09_1.mp3"
          },
          {
            "num": "TG10",
            "audioPath": "demo1/bpm75/LCOT TG10_1.mp3"
          },
          {
            "num": "TG11",
            "audioPath": "demo1/bpm75/LCOT TG11_1.mp3"
          },
          {
            "num": "TG12",
            "audioPath": "demo1/bpm75/LCOT TG12_1.mp3"
          },
          {
            "num": "TG13",
            "audioPath": "demo1/bpm75/LCOT TG13_1.mp3"
          },
          {
            "num": "TG14",
            "audioPath": "demo1/bpm75/LCOT TG14_1.mp3"
          },
          {
            "num": "TG15",
            "audioPath": "demo1/bpm75/LCOT TG15_1.mp3"
          },
          {
            "num": "TG16",
            "audioPath": "demo1/bpm75/LCOT TG16_1.mp3"
          },
          {
            "num": "TG17",
            "audioPath": "demo1/bpm75/LCOT TG17_1.mp3"
          },
          {
            "num": "TG18",
            "audioPath": "demo1/bpm75/LCOT TG18_1.mp3"
          },
          {
            "num": "TG19",
            "audioPath": "demo1/bpm75/LCOT TG19_1.mp3"
          },
          {
            "num": "TG20",
            "audioPath": "demo1/bpm75/LCOT TG20_1.mp3"
          },
          {
            "num": "TG21",
            "audioPath": "demo1/bpm75/LCOT TG21_1.mp3"
          },
          {
            "num": "TG22",
            "audioPath": "demo1/bpm75/LCOT TG22_1.mp3"
          },
          {
            "num": "TG23",
            "audioPath": "demo1/bpm75/LCOT TG23_1.mp3"
          },
          {
            "num": "TG24",
            "audioPath": "demo1/bpm75/LCOT TG24_1.mp3"
          },
          {
            "num": "TG25",
            "audioPath": "demo1/bpm75/LCOT TG25_1.mp3"
          },
          {
            "num": "TG26",
            "audioPath": "demo1/bpm75/LCOT TG26_1.mp3"
          },
          {
            "num": "TG27",
            "audioPath": "demo1/bpm75/LCOT TG27_1.mp3"
          },
          {
            "num": "TG28",
            "audioPath": "demo1/bpm75/LCOT TG28_1.mp3"
          },
          {
            "num": "TG29",
            "audioPath": "demo1/bpm75/LCOT TG29_1.mp3"
          },
          {
            "num": "TG30",
            "audioPath": "demo1/bpm75/LCOT TG30_1.mp3"
          }
        ],
        "FS": [
          {
            "num": "01",
            "audioPath": "demo1/bpm75/FS 01_1.mp3"
          },
          {
            "num": "02",
            "audioPath": "demo1/bpm75/FS 02_1.mp3"
          },
          {
            "num": "03",
            "audioPath": "demo1/bpm75/FS 03_1.mp3"
          },
          {
            "num": "04",
            "audioPath": "demo1/bpm75/FS 04_1.mp3"
          },
          {
            "num": "05",
            "audioPath": "demo1/bpm75/FS 05_1.mp3"
          },
          {
            "num": "06",
            "audioPath": "demo1/bpm75/FS 06_1.mp3"
          },
          {
            "num": "07",
            "audioPath": "demo1/bpm75/FS 07_1.mp3"
          },
          {
            "num": "08",
            "audioPath": "demo1/bpm75/FS 08_1.mp3"
          },
          {
            "num": "09",
            "audioPath": "demo1/bpm75/FS 09_1.mp3"
          },
          {
            "num": "10",
            "audioPath": "demo1/bpm75/FS 10_1.mp3"
          },
          {
            "num": "11",
            "audioPath": "demo1/bpm75/FS 11_1.mp3"
          },
          {
            "num": "12",
            "audioPath": "demo1/bpm75/FS 12_1.mp3"
          },
          {
            "num": "13",
            "audioPath": "demo1/bpm75/FS 13_1.mp3"
          },
          {
            "num": "14",
            "audioPath": "demo1/bpm75/FS 14_1.mp3"
          },
          {
            "num": "15",
            "audioPath": "demo1/bpm75/FS 15_1.mp3"
          },
          {
            "num": "16",
            "audioPath": "demo1/bpm75/FS 16_1.mp3"
          },
          {
            "num": "17",
            "audioPath": "demo1/bpm75/FS 17_1.mp3"
          },
          {
            "num": "18",
            "audioPath": "demo1/bpm75/FS 18_1.mp3"
          },
          {
            "num": "19",
            "audioPath": "demo1/bpm75/FS 19_1.mp3"
          },
          {
            "num": "20",
            "audioPath": "demo1/bpm75/FS 20_1.mp3"
          },
          {
            "num": "21",
            "audioPath": "demo1/bpm75/FS 21_1.mp3"
          },
          {
            "num": "22",
            "audioPath": "demo1/bpm75/FS 22_1.mp3"
          },
          {
            "num": "23",
            "audioPath": "demo1/bpm75/FS 23_1.mp3"
          },
          {
            "num": "24",
            "audioPath": "demo1/bpm75/FS 24_1.mp3"
          },
          {
            "num": "25",
            "audioPath": "demo1/bpm75/FS 25_1.mp3"
          },
          {
            "num": "26",
            "audioPath": "demo1/bpm75/FS 26_1.mp3"
          },
          {
            "num": "27",
            "audioPath": "demo1/bpm75/FS 27_1.mp3"
          },
          {
            "num": "28",
            "audioPath": "demo1/bpm75/FS 28_1.mp3"
          },
          {
            "num": "29",
            "audioPath": "demo1/bpm75/FS 29_1.mp3"
          },
          {
            "num": "30",
            "audioPath": "demo1/bpm75/FS 30_1.mp3"
          },
          {
            "num": "31",
            "audioPath": "demo1/bpm75/FS 31_1.mp3"
          },
          {
            "num": "32",
            "audioPath": "demo1/bpm75/FS 32_1.mp3"
          },
          {
            "num": "33",
            "audioPath": "demo1/bpm75/FS 33_1.mp3"
          },
          {
            "num": "34",
            "audioPath": "demo1/bpm75/FS 34_1.mp3"
          },
          {
            "num": "35",
            "audioPath": "demo1/bpm75/FS 35_1.mp3"
          },
          {
            "num": "36",
            "audioPath": "demo1/bpm75/FS 36_1.mp3"
          },
          {
            "num": "37",
            "audioPath": "demo1/bpm75/FS 37_1.mp3"
          },
          {
            "num": "38",
            "audioPath": "demo1/bpm75/FS 38_1.mp3"
          },
          {
            "num": "39",
            "audioPath": "demo1/bpm75/FS 39_1.mp3"
          },
          {
            "num": "40",
            "audioPath": "demo1/bpm75/FS 40_1.mp3"
          },
          {
            "num": "41",
            "audioPath": "demo1/bpm75/FS 41_1.mp3"
          },
          {
            "num": "42",
            "audioPath": "demo1/bpm75/FS 42_1.mp3"
          },
          {
            "num": "43",
            "audioPath": "demo1/bpm75/FS 43_1.mp3"
          },
          {
            "num": "44",
            "audioPath": "demo1/bpm75/FS 44_1.mp3"
          },
          {
            "num": "45",
            "audioPath": "demo1/bpm75/FS 45_1.mp3"
          },
          {
            "num": "46",
            "audioPath": "demo1/bpm75/FS 46_1.mp3"
          },
          {
            "num": "47",
            "audioPath": "demo1/bpm75/FS 47_1.mp3"
          },
          {
            "num": "48",
            "audioPath": "demo1/bpm75/FS 48_1.mp3"
          }
        ],
        "OS": [
          {
            "num": "01",
            "audioPath": "demo1/bpm75/OS  01_1.mp3"
          },
          {
            "num": "01a",
            "audioPath": "demo1/bpm75/OS  01a_1.mp3"
          },
          {
            "num": "02",
            "audioPath": "demo1/bpm75/OS  02_1.mp3"
          },
          {
            "num": "02a",
            "audioPath": "demo1/bpm75/OS  02a_1.mp3"
          },
          {
            "num": "03",
            "audioPath": "demo1/bpm75/OS  03_1.mp3"
          },
          {
            "num": "03a",
            "audioPath": "demo1/bpm75/OS  03a_1.mp3"
          },
          {
            "num": "04",
            "audioPath": "demo1/bpm75/OS  04_1.mp3"
          },
          {
            "num": "04a",
            "audioPath": "demo1/bpm75/OS  04a_1.mp3"
          },
          {
            "num": "05",
            "audioPath": "demo1/bpm75/OS  05_1.mp3"
          },
          {
            "num": "06",
            "audioPath": "demo1/bpm75/OS  06_1.mp3"
          },
          {
            "num": "07",
            "audioPath": "demo1/bpm75/OS  07_1.mp3"
          },
          {
            "num": "08",
            "audioPath": "demo1/bpm75/OS  08_1.mp3"
          },
          {
            "num": "09",
            "audioPath": "demo1/bpm75/OS  09_1.mp3"
          },
          {
            "num": "09a",
            "audioPath": "demo1/bpm75/OS  09a_1.mp3"
          },
          {
            "num": "10",
            "audioPath": "demo1/bpm75/OS  10_1.mp3"
          },
          {
            "num": "11",
            "audioPath": "demo1/bpm75/OS  11_1.mp3"
          },
          {
            "num": "12",
            "audioPath": "demo1/bpm75/OS  12  --  low strings_1.mp3"
          },
          {
            "num": "12a",
            "audioPath": "demo1/bpm75/OS  12a_1.mp3"
          },
          {
            "num": "13",
            "audioPath": "demo1/bpm75/OS  13_1.mp3"
          },
          {
            "num": "13a",
            "audioPath": "demo1/bpm75/OS  13a_1.mp3"
          },
          {
            "num": "14",
            "audioPath": "demo1/bpm75/OS  14_1.mp3"
          },
          {
            "num": "14a",
            "audioPath": "demo1/bpm75/OS  14a_1.mp3"
          },
          {
            "num": "15",
            "audioPath": "demo1/bpm75/OS  15_1.mp3"
          },
          {
            "num": "15a",
            "audioPath": "demo1/bpm75/OS  15a_1.mp3"
          },
          {
            "num": "16",
            "audioPath": "demo1/bpm75/OS  16_1.mp3"
          },
          {
            "num": "17",
            "audioPath": "demo1/bpm75/OS  17_1.mp3"
          },
          {
            "num": "17a",
            "audioPath": "demo1/bpm75/OS  17a_1.mp3"
          },
          {
            "num": "18",
            "audioPath": "demo1/bpm75/OS  18_1.mp3"
          },
          {
            "num": "19",
            "audioPath": "demo1/bpm75/OS  19_1.mp3"
          },
          {
            "num": "20",
            "audioPath": "demo1/bpm75/OS  20_1.mp3"
          },
          {
            "num": "20a",
            "audioPath": "demo1/bpm75/OS  20a_1.mp3"
          },
          {
            "num": "21",
            "audioPath": "demo1/bpm75/OS  21_1.mp3"
          },
          {
            "num": "21a",
            "audioPath": "demo1/bpm75/OS  21a_1.mp3"
          },
          {
            "num": "22",
            "audioPath": "demo1/bpm75/OS  22_1.mp3"
          },
          {
            "num": "23",
            "audioPath": "demo1/bpm75/OS  23_1.mp3"
          },
          {
            "num": "24",
            "audioPath": "demo1/bpm75/OS  24 -- hi woods_1.mp3"
          },
          {
            "num": "24a",
            "audioPath": "demo1/bpm75/OS  24a_1.mp3"
          },
          {
            "num": "25",
            "audioPath": "demo1/bpm75/OS  25_1.mp3"
          },
          {
            "num": "25a",
            "audioPath": "demo1/bpm75/OS  25a_1.mp3"
          },
          {
            "num": "26",
            "audioPath": "demo1/bpm75/OS  26_1.mp3"
          },
          {
            "num": "27",
            "audioPath": "demo1/bpm75/OS  27_1.mp3"
          },
          {
            "num": "28",
            "audioPath": "demo1/bpm75/OS  28_1.mp3"
          },
          {
            "num": "28a",
            "audioPath": "demo1/bpm75/OS  28a_1.mp3"
          },
          {
            "num": "29",
            "audioPath": "demo1/bpm75/OS  29_1.mp3"
          },
          {
            "num": "30",
            "audioPath": "demo1/bpm75/OS  30_1.mp3"
          },
          {
            "num": "31",
            "audioPath": "demo1/bpm75/OS  31_1.mp3"
          },
          {
            "num": "32",
            "audioPath": "demo1/bpm75/OS  32_1.mp3"
          },
          {
            "num": "33",
            "audioPath": "demo1/bpm75/OS  33_1.mp3"
          },
          {
            "num": "34",
            "audioPath": "demo1/bpm75/OS  34_1.mp3"
          },
          {
            "num": "35",
            "audioPath": "demo1/bpm75/OS  35 -- low wood_1.mp3"
          },
          {
            "num": "35a",
            "audioPath": "demo1/bpm75/OS  35a_1.mp3"
          },
          {
            "num": "36",
            "audioPath": "demo1/bpm75/OS  36_1.mp3"
          },
          {
            "num": "37",
            "audioPath": "demo1/bpm75/OS  37_1.mp3"
          },
          {
            "num": "38",
            "audioPath": "demo1/bpm75/OS  38_1.mp3"
          },
          {
            "num": "39",
            "audioPath": "demo1/bpm75/OS  39_1.mp3"
          },
          {
            "num": "40",
            "audioPath": "demo1/bpm75/OS  40_1.mp3"
          },
          {
            "num": "41",
            "audioPath": "demo1/bpm75/OS  41_1.mp3"
          },
          {
            "num": "42",
            "audioPath": "demo1/bpm75/OS  42 --  Horns_1.mp3"
          },
          {
            "num": "42a",
            "audioPath": "demo1/bpm75/OS  42a_1.mp3"
          },
          {
            "num": "43",
            "audioPath": "demo1/bpm75/OS  43_1.mp3"
          },
          {
            "num": "44",
            "audioPath": "demo1/bpm75/OS  44_1.mp3"
          },
          {
            "num": "44a",
            "audioPath": "demo1/bpm75/OS  44a_1.mp3"
          },
          {
            "num": "45",
            "audioPath": "demo1/bpm75/OS  45_1.mp3"
          },
          {
            "num": "46",
            "audioPath": "demo1/bpm75/OS 46_1.mp3"
          },
          {
            "num": "47",
            "audioPath": "demo1/bpm75/OS 47_1.mp3"
          },
          {
            "num": "48",
            "audioPath": "demo1/bpm75/OS 48_1.mp3"
          },
          {
            "num": "49",
            "audioPath": "demo1/bpm75/OS 49  -- Bones_1.mp3"
          },
          {
            "num": "49a",
            "audioPath": "demo1/bpm75/OS 49a_1.mp3"
          },
          {
            "num": "50",
            "audioPath": "demo1/bpm75/OS 50_1.mp3"
          },
          {
            "num": "51",
            "audioPath": "demo1/bpm75/OS 51_1.mp3"
          },
          {
            "num": "52",
            "audioPath": "demo1/bpm75/OS 52_1.mp3"
          },
          {
            "num": "53",
            "audioPath": "demo1/bpm75/OS 53_1.mp3"
          },
          {
            "num": "54",
            "audioPath": "demo1/bpm75/OS 54_1.mp3"
          },
          {
            "num": "55",
            "audioPath": "demo1/bpm75/OS 55_1.mp3"
          },
          {
            "num": "56",
            "audioPath": "demo1/bpm75/OS 56  -- brass_1.mp3"
          },
          {
            "num": "57",
            "audioPath": "demo1/bpm75/OS 57_1.mp3"
          }
        ],
        "EWC": [
          {
            "num": "01",
            "audioPath": "demo1/bpm75/EWC 01_1.mp3"
          },
          {
            "num": "02",
            "audioPath": "demo1/bpm75/EWC 02_1.mp3"
          },
          {
            "num": "03",
            "audioPath": "demo1/bpm75/EWC 03_1.mp3"
          },
          {
            "num": "04",
            "audioPath": "demo1/bpm75/EWC 04_1.mp3"
          },
          {
            "num": "05",
            "audioPath": "demo1/bpm75/EWC 05_1.mp3"
          },
          {
            "num": "06",
            "audioPath": "demo1/bpm75/EWC 06_1.mp3"
          },
          {
            "num": "07",
            "audioPath": "demo1/bpm75/EWC 07_1.mp3"
          },
          {
            "num": "08",
            "audioPath": "demo1/bpm75/EWC 08_1.mp3"
          },
          {
            "num": "09",
            "audioPath": "demo1/bpm75/EWC 09_1.mp3"
          },
          {
            "num": "10",
            "audioPath": "demo1/bpm75/EWC 10_1.mp3"
          },
          {
            "num": "11",
            "audioPath": "demo1/bpm75/EWC 11_1.mp3"
          },
          {
            "num": "12",
            "audioPath": "demo1/bpm75/EWC 12_1.mp3"
          },
          {
            "num": "13",
            "audioPath": "demo1/bpm75/EWC 13_1.mp3"
          },
          {
            "num": "14",
            "audioPath": "demo1/bpm75/EWC 14_1.mp3"
          },
          {
            "num": "15",
            "audioPath": "demo1/bpm75/EWC 15_1.mp3"
          },
          {
            "num": "16",
            "audioPath": "demo1/bpm75/EWC 16_1.mp3"
          },
          {
            "num": "17",
            "audioPath": "demo1/bpm75/EWC 17_1.mp3"
          },
          {
            "num": "18",
            "audioPath": "demo1/bpm75/EWC 18_1.mp3"
          },
          {
            "num": "19",
            "audioPath": "demo1/bpm75/EWC 19_1.mp3"
          },
          {
            "num": "20",
            "audioPath": "demo1/bpm75/EWC 20_1.mp3"
          },
          {
            "num": "21",
            "audioPath": "demo1/bpm75/EWC 21_1.mp3"
          },
          {
            "num": "22",
            "audioPath": "demo1/bpm75/EWC 22_1.mp3"
          },
          {
            "num": "23",
            "audioPath": "demo1/bpm75/EWC 23_1.mp3"
          },
          {
            "num": "24",
            "audioPath": "demo1/bpm75/EWC 24_1.mp3"
          },
          {
            "num": "25",
            "audioPath": "demo1/bpm75/EWC 25_1.mp3"
          },
          {
            "num": "26",
            "audioPath": "demo1/bpm75/EWC 26_1.mp3"
          },
          {
            "num": "27",
            "audioPath": "demo1/bpm75/EWC 27_1.mp3"
          },
          {
            "num": "28",
            "audioPath": "demo1/bpm75/EWC 28_1.mp3"
          },
          {
            "num": "29",
            "audioPath": "demo1/bpm75/EWC 29_1.mp3"
          },
          {
            "num": "30",
            "audioPath": "demo1/bpm75/EWC 30_1.mp3"
          },
          {
            "num": "31",
            "audioPath": "demo1/bpm75/EWC 31_1.mp3"
          },
          {
            "num": "32",
            "audioPath": "demo1/bpm75/EWC 32_1.mp3"
          },
          {
            "num": "33",
            "audioPath": "demo1/bpm75/EWC 33_1.mp3"
          },
          {
            "num": "34",
            "audioPath": "demo1/bpm75/EWC 34_1.mp3"
          },
          {
            "num": "35",
            "audioPath": "demo1/bpm75/EWC 35_1.mp3"
          },
          {
            "num": "36",
            "audioPath": "demo1/bpm75/EWC 36_1.mp3"
          },
          {
            "num": "37",
            "audioPath": "demo1/bpm75/EWC 37_1.mp3"
          },
          {
            "num": "38",
            "audioPath": "demo1/bpm75/EWC 38_1.mp3"
          },
          {
            "num": "39",
            "audioPath": "demo1/bpm75/EWC 39_1.mp3"
          },
          {
            "num": "40",
            "audioPath": "demo1/bpm75/EWC 40_1.mp3"
          },
          {
            "num": "41",
            "audioPath": "demo1/bpm75/EWC 41_1.mp3"
          },
          {
            "num": "42",
            "audioPath": "demo1/bpm75/EWC 42_1.mp3"
          },
          {
            "num": "43",
            "audioPath": "demo1/bpm75/EWC 43_1.mp3"
          },
          {
            "num": "44",
            "audioPath": "demo1/bpm75/EWC 44_1.mp3"
          },
          {
            "num": "45",
            "audioPath": "demo1/bpm75/EWC 45_1.mp3"
          },
          {
            "num": "46",
            "audioPath": "demo1/bpm75/EWC 46_1.mp3"
          },
          {
            "num": "47",
            "audioPath": "demo1/bpm75/EWC 47_1.mp3"
          },
          {
            "num": "48",
            "audioPath": "demo1/bpm75/EWC 48_1.mp3"
          },
          {
            "num": "49",
            "audioPath": "demo1/bpm75/EWC 49_1.mp3"
          },
          {
            "num": "50",
            "audioPath": "demo1/bpm75/EWC 50_1.mp3"
          },
          {
            "num": "51",
            "audioPath": "demo1/bpm75/EWC 51_1.mp3"
          }
        ],
        "IS": [
          {
            "num": "01",
            "audioPath": "demo1/bpm75/IS 01_1.mp3"
          },
          {
            "num": "02",
            "audioPath": "demo1/bpm75/IS 02_1.mp3"
          },
          {
            "num": "03",
            "audioPath": "demo1/bpm75/IS 03_1.mp3"
          },
          {
            "num": "04",
            "audioPath": "demo1/bpm75/IS 04_1.mp3"
          },
          {
            "num": "05",
            "audioPath": "demo1/bpm75/IS 05_1.mp3"
          }
        ],
        "CFS": [
          {
            "num": "01",
            "audioPath": "demo1/bpm75/CFS 01_1.mp3"
          },
          {
            "num": "02",
            "audioPath": "demo1/bpm75/CFS 02_1.mp3"
          },
          {
            "num": "03",
            "audioPath": "demo1/bpm75/CFS 03_1.mp3"
          },
          {
            "num": "05",
            "audioPath": "demo1/bpm75/CFS 05_1.mp3"
          },
          {
            "num": "06",
            "audioPath": "demo1/bpm75/CFS 06_1.mp3"
          },
          {
            "num": "07",
            "audioPath": "demo1/bpm75/CFS 07_1.mp3"
          },
          {
            "num": "08",
            "audioPath": "demo1/bpm75/CFS 08_1.mp3"
          }
        ],
        "OAE": [
          {
            "num": "01",
            "audioPath": "demo1/bpm75/OAE 01_1.mp3"
          },
          {
            "num": "01v1",
            "audioPath": "demo1/bpm75/OAE 01v1_1.mp3"
          },
          {
            "num": "01v2",
            "audioPath": "demo1/bpm75/OAE 01v2_1.mp3"
          },
          {
            "num": "01va",
            "audioPath": "demo1/bpm75/OAE 01va_1.mp3"
          },
          {
            "num": "01vc",
            "audioPath": "demo1/bpm75/OAE 01vc_1.mp3"
          },
          {
            "num": "02",
            "audioPath": "demo1/bpm75/OAE 02_1.mp3"
          },
          {
            "num": "02v1",
            "audioPath": "demo1/bpm75/OAE 02v1_1.mp3"
          },
          {
            "num": "02v2",
            "audioPath": "demo1/bpm75/OAE 02v2_1.mp3"
          },
          {
            "num": "02va",
            "audioPath": "demo1/bpm75/OAE 02va_1.mp3"
          },
          {
            "num": "02vc",
            "audioPath": "demo1/bpm75/OAE 02vc_1.mp3"
          },
          {
            "num": "03",
            "audioPath": "demo1/bpm75/OAE 03_1.mp3"
          },
          {
            "num": "03v1",
            "audioPath": "demo1/bpm75/OAE 03v1_1.mp3"
          },
          {
            "num": "03v2",
            "audioPath": "demo1/bpm75/OAE 03v2_1.mp3"
          },
          {
            "num": "03va",
            "audioPath": "demo1/bpm75/OAE 03va_1.mp3"
          },
          {
            "num": "03vc",
            "audioPath": "demo1/bpm75/OAE 03vc_1.mp3"
          },
          {
            "num": "04",
            "audioPath": "demo1/bpm75/OAE 04_1.mp3"
          },
          {
            "num": "04v1",
            "audioPath": "demo1/bpm75/OAE 04v1_1.mp3"
          },
          {
            "num": "04v2",
            "audioPath": "demo1/bpm75/OAE 04v2_1.mp3"
          },
          {
            "num": "04va",
            "audioPath": "demo1/bpm75/OAE 04va_1.mp3"
          },
          {
            "num": "04vc",
            "audioPath": "demo1/bpm75/OAE 04vc_1.mp3"
          },
          {
            "num": "05",
            "audioPath": "demo1/bpm75/OAE 05_1.mp3"
          },
          {
            "num": "05v1",
            "audioPath": "demo1/bpm75/OAE 05v1_1.mp3"
          },
          {
            "num": "05v2",
            "audioPath": "demo1/bpm75/OAE 05v2_1.mp3"
          },
          {
            "num": "05va",
            "audioPath": "demo1/bpm75/OAE 05va_1.mp3"
          },
          {
            "num": "05vc",
            "audioPath": "demo1/bpm75/OAE 05vc_1.mp3"
          },
          {
            "num": "06",
            "audioPath": "demo1/bpm75/OAE 06_1.mp3"
          },
          {
            "num": "06v1",
            "audioPath": "demo1/bpm75/OAE 06v1_1.mp3"
          },
          {
            "num": "06v2",
            "audioPath": "demo1/bpm75/OAE 06v2_1.mp3"
          },
          {
            "num": "06va",
            "audioPath": "demo1/bpm75/OAE 06va_1.mp3"
          },
          {
            "num": "06vc",
            "audioPath": "demo1/bpm75/OAE 06vc_1.mp3"
          },
          {
            "num": "07",
            "audioPath": "demo1/bpm75/OAE 07_1.mp3"
          },
          {
            "num": "07v1",
            "audioPath": "demo1/bpm75/OAE 07v1_1.mp3"
          },
          {
            "num": "07v2",
            "audioPath": "demo1/bpm75/OAE 07v2_1.mp3"
          },
          {
            "num": "07va",
            "audioPath": "demo1/bpm75/OAE 07va_1.mp3"
          },
          {
            "num": "07vc",
            "audioPath": "demo1/bpm75/OAE 07vc_1.mp3"
          },
          {
            "num": "08",
            "audioPath": "demo1/bpm75/OAE 08_1.mp3"
          },
          {
            "num": "08v1",
            "audioPath": "demo1/bpm75/OAE 08v1_1.mp3"
          },
          {
            "num": "08v2",
            "audioPath": "demo1/bpm75/OAE 08v2_1.mp3"
          },
          {
            "num": "08va",
            "audioPath": "demo1/bpm75/OAE 08va_1.mp3"
          },
          {
            "num": "08vc",
            "audioPath": "demo1/bpm75/OAE 08vc_1.mp3"
          },
          {
            "num": "09",
            "audioPath": "demo1/bpm75/OAE 09_1.mp3"
          },
          {
            "num": "09v1",
            "audioPath": "demo1/bpm75/OAE 09v1_1.mp3"
          },
          {
            "num": "09v2",
            "audioPath": "demo1/bpm75/OAE 09v2_1.mp3"
          },
          {
            "num": "09va",
            "audioPath": "demo1/bpm75/OAE 09va_1.mp3"
          },
          {
            "num": "09vc",
            "audioPath": "demo1/bpm75/OAE 09vc_1.mp3"
          },
          {
            "num": "10",
            "audioPath": "demo1/bpm75/OAE 10_1.mp3"
          },
          {
            "num": "10v1",
            "audioPath": "demo1/bpm75/OAE 10v1_1.mp3"
          },
          {
            "num": "10v2",
            "audioPath": "demo1/bpm75/OAE 10v2_1.mp3"
          },
          {
            "num": "10va",
            "audioPath": "demo1/bpm75/OAE 10va_1.mp3"
          },
          {
            "num": "10vc",
            "audioPath": "demo1/bpm75/OAE 10vc_1.mp3"
          },
          {
            "num": "11",
            "audioPath": "demo1/bpm75/OAE 11_1.mp3"
          },
          {
            "num": "11v1",
            "audioPath": "demo1/bpm75/OAE 11v1_1.mp3"
          },
          {
            "num": "11v2",
            "audioPath": "demo1/bpm75/OAE 11v2_1.mp3"
          },
          {
            "num": "11va",
            "audioPath": "demo1/bpm75/OAE 11va_1.mp3"
          },
          {
            "num": "11vc",
            "audioPath": "demo1/bpm75/OAE 11vc_1.mp3"
          },
          {
            "num": "12",
            "audioPath": "demo1/bpm75/OAE 12_1.mp3"
          },
          {
            "num": "12v1",
            "audioPath": "demo1/bpm75/OAE 12v1_1.mp3"
          },
          {
            "num": "12v2",
            "audioPath": "demo1/bpm75/OAE 12v2_1.mp3"
          },
          {
            "num": "12va",
            "audioPath": "demo1/bpm75/OAE 12va_1.mp3"
          },
          {
            "num": "12vc",
            "audioPath": "demo1/bpm75/OAE 12vc_1.mp3"
          },
          {
            "num": "13",
            "audioPath": "demo1/bpm75/OAE 13_1.mp3"
          },
          {
            "num": "13v1",
            "audioPath": "demo1/bpm75/OAE 13v1_1.mp3"
          },
          {
            "num": "13v2",
            "audioPath": "demo1/bpm75/OAE 13v2_1.mp3"
          },
          {
            "num": "13va",
            "audioPath": "demo1/bpm75/OAE 13va_1.mp3"
          },
          {
            "num": "13vc",
            "audioPath": "demo1/bpm75/OAE 13vc_1.mp3"
          },
          {
            "num": "14",
            "audioPath": "demo1/bpm75/OAE 14_1.mp3"
          },
          {
            "num": "14v1",
            "audioPath": "demo1/bpm75/OAE 14v1_1.mp3"
          },
          {
            "num": "14v2",
            "audioPath": "demo1/bpm75/OAE 14v2_1.mp3"
          },
          {
            "num": "14va",
            "audioPath": "demo1/bpm75/OAE 14va_1.mp3"
          },
          {
            "num": "14vc",
            "audioPath": "demo1/bpm75/OAE 14vc_1.mp3"
          },
          {
            "num": "15",
            "audioPath": "demo1/bpm75/OAE 15_1.mp3"
          },
          {
            "num": "15v1",
            "audioPath": "demo1/bpm75/OAE 15v1_1.mp3"
          },
          {
            "num": "15v2",
            "audioPath": "demo1/bpm75/OAE 15v2_1.mp3"
          },
          {
            "num": "15va",
            "audioPath": "demo1/bpm75/OAE 15va_1.mp3"
          },
          {
            "num": "15vc",
            "audioPath": "demo1/bpm75/OAE 15vc_1.mp3"
          },
          {
            "num": "16",
            "audioPath": "demo1/bpm75/OAE 16_1.mp3"
          },
          {
            "num": "16v1",
            "audioPath": "demo1/bpm75/OAE 16v1_1.mp3"
          },
          {
            "num": "16v2",
            "audioPath": "demo1/bpm75/OAE 16v2_1.mp3"
          },
          {
            "num": "16va",
            "audioPath": "demo1/bpm75/OAE 16va_1.mp3"
          },
          {
            "num": "16vc",
            "audioPath": "demo1/bpm75/OAE 16vc_1.mp3"
          }
        ],
        "TND": [
          {
            "num": "01",
            "audioPath": "demo1/bpm75/TND 01_1.mp3"
          },
          {
            "num": "01hi",
            "audioPath": "demo1/bpm75/TND 01hi_1.mp3"
          },
          {
            "num": "01lo",
            "audioPath": "demo1/bpm75/TND 01lo_1.mp3"
          },
          {
            "num": "02",
            "audioPath": "demo1/bpm75/TND 02_1.mp3"
          },
          {
            "num": "02hi",
            "audioPath": "demo1/bpm75/TND 02hi_1.mp3"
          },
          {
            "num": "02lo",
            "audioPath": "demo1/bpm75/TND 02lo_1.mp3"
          },
          {
            "num": "03",
            "audioPath": "demo1/bpm75/TND 03_1.mp3"
          },
          {
            "num": "03hi",
            "audioPath": "demo1/bpm75/TND 03hi_1.mp3"
          },
          {
            "num": "03lo",
            "audioPath": "demo1/bpm75/TND 03lo_1.mp3"
          },
          {
            "num": "04",
            "audioPath": "demo1/bpm75/TND 04_1.mp3"
          },
          {
            "num": "04hi",
            "audioPath": "demo1/bpm75/TND 04hi_1.mp3"
          },
          {
            "num": "04lo",
            "audioPath": "demo1/bpm75/TND 04lo_1.mp3"
          },
          {
            "num": "05",
            "audioPath": "demo1/bpm75/TND 05_1.mp3"
          },
          {
            "num": "05hi",
            "audioPath": "demo1/bpm75/TND 05hi_1.mp3"
          },
          {
            "num": "05lo",
            "audioPath": "demo1/bpm75/TND 05lo_1.mp3"
          },
          {
            "num": "06",
            "audioPath": "demo1/bpm75/TND 06_1.mp3"
          },
          {
            "num": "06hi",
            "audioPath": "demo1/bpm75/TND 06hi_1.mp3"
          },
          {
            "num": "06lo",
            "audioPath": "demo1/bpm75/TND 06lo_1.mp3"
          },
          {
            "num": "07",
            "audioPath": "demo1/bpm75/TND 07_1.mp3"
          },
          {
            "num": "07hi",
            "audioPath": "demo1/bpm75/TND 07hi_1.mp3"
          },
          {
            "num": "07lo",
            "audioPath": "demo1/bpm75/TND 07lo_1.mp3"
          },
          {
            "num": "08",
            "audioPath": "demo1/bpm75/TND 08_1.mp3"
          },
          {
            "num": "08hi",
            "audioPath": "demo1/bpm75/TND 08hi_1.mp3"
          },
          {
            "num": "08lo",
            "audioPath": "demo1/bpm75/TND 08lo_1.mp3"
          },
          {
            "num": "09",
            "audioPath": "demo1/bpm75/TND 09_1.mp3"
          },
          {
            "num": "09hi",
            "audioPath": "demo1/bpm75/TND 09hi_1.mp3"
          },
          {
            "num": "09lo",
            "audioPath": "demo1/bpm75/TND 09lo_1.mp3"
          },
          {
            "num": "10",
            "audioPath": "demo1/bpm75/TND 10_1.mp3"
          },
          {
            "num": "10hi",
            "audioPath": "demo1/bpm75/TND 10hi_1.mp3"
          },
          {
            "num": "10lo",
            "audioPath": "demo1/bpm75/TND 10lo_1.mp3"
          },
          {
            "num": "11",
            "audioPath": "demo1/bpm75/TND 11_1.mp3"
          },
          {
            "num": "11hi",
            "audioPath": "demo1/bpm75/TND 11hi_1.mp3"
          },
          {
            "num": "11lo",
            "audioPath": "demo1/bpm75/TND 11lo_1.mp3"
          },
          {
            "num": "12",
            "audioPath": "demo1/bpm75/TND 12_1.mp3"
          },
          {
            "num": "12hi",
            "audioPath": "demo1/bpm75/TND 12hi_1.mp3"
          },
          {
            "num": "12lo",
            "audioPath": "demo1/bpm75/TND 12lo_1.mp3"
          },
          {
            "num": "13",
            "audioPath": "demo1/bpm75/TND 13_1.mp3"
          },
          {
            "num": "13hi",
            "audioPath": "demo1/bpm75/TND 13hi_1.mp3"
          },
          {
            "num": "13lo",
            "audioPath": "demo1/bpm75/TND 13lo_1.mp3"
          },
          {
            "num": "14",
            "audioPath": "demo1/bpm75/TND 14_1.mp3"
          },
          {
            "num": "14hi",
            "audioPath": "demo1/bpm75/TND 14hi_1.mp3"
          },
          {
            "num": "14lo",
            "audioPath": "demo1/bpm75/TND 14lo_1.mp3"
          },
          {
            "num": "15",
            "audioPath": "demo1/bpm75/TND 15_1.mp3"
          },
          {
            "num": "15hi",
            "audioPath": "demo1/bpm75/TND 15hi_1.mp3"
          },
          {
            "num": "15lo",
            "audioPath": "demo1/bpm75/TND 15lo_1.mp3"
          },
          {
            "num": "16",
            "audioPath": "demo1/bpm75/TND 16_1.mp3"
          },
          {
            "num": "16hi",
            "audioPath": "demo1/bpm75/TND 16hi_1.mp3"
          },
          {
            "num": "16lo",
            "audioPath": "demo1/bpm75/TND 16lo_1.mp3"
          },
          {
            "num": "17",
            "audioPath": "demo1/bpm75/TND 17_1.mp3"
          },
          {
            "num": "18",
            "audioPath": "demo1/bpm75/TND 18_1.mp3"
          },
          {
            "num": "19",
            "audioPath": "demo1/bpm75/TND 19_1.mp3"
          },
          {
            "num": "19hi",
            "audioPath": "demo1/bpm75/TND 19hi_1.mp3"
          },
          {
            "num": "19lo",
            "audioPath": "demo1/bpm75/TND 19lo_1.mp3"
          },
          {
            "num": "20",
            "audioPath": "demo1/bpm75/TND 20_1.mp3"
          },
          {
            "num": "20hi",
            "audioPath": "demo1/bpm75/TND 20hi_1.mp3"
          },
          {
            "num": "20lo",
            "audioPath": "demo1/bpm75/TND 20lo_1.mp3"
          },
          {
            "num": "21",
            "audioPath": "demo1/bpm75/TND 21_1.mp3"
          },
          {
            "num": "21hi",
            "audioPath": "demo1/bpm75/TND 21hi_1.mp3"
          },
          {
            "num": "21lo",
            "audioPath": "demo1/bpm75/TND 21lo_1.mp3"
          },
          {
            "num": "22",
            "audioPath": "demo1/bpm75/TND 22_1.mp3"
          },
          {
            "num": "22hi",
            "audioPath": "demo1/bpm75/TND 22hi_1.mp3"
          },
          {
            "num": "22lo",
            "audioPath": "demo1/bpm75/TND 22lo_1.mp3"
          },
          {
            "num": "23",
            "audioPath": "demo1/bpm75/TND 23_1.mp3"
          },
          {
            "num": "23hi",
            "audioPath": "demo1/bpm75/TND 23hi_1.mp3"
          },
          {
            "num": "23lo",
            "audioPath": "demo1/bpm75/TND 23lo_1.mp3"
          },
          {
            "num": "24",
            "audioPath": "demo1/bpm75/TND 24_1.mp3"
          },
          {
            "num": "24hi",
            "audioPath": "demo1/bpm75/TND 24hi_1.mp3"
          },
          {
            "num": "24lo",
            "audioPath": "demo1/bpm75/TND 24lo_1.mp3"
          },
          {
            "num": "25",
            "audioPath": "demo1/bpm75/TND 25_1.mp3"
          },
          {
            "num": "25hi",
            "audioPath": "demo1/bpm75/TND 25hi_1.mp3"
          },
          {
            "num": "25lo",
            "audioPath": "demo1/bpm75/TND 25lo_1.mp3"
          },
          {
            "num": "26",
            "audioPath": "demo1/bpm75/TND 26_1.mp3"
          },
          {
            "num": "26hi",
            "audioPath": "demo1/bpm75/TND 26hi_1.mp3"
          },
          {
            "num": "26lo",
            "audioPath": "demo1/bpm75/TND 26lo_1.mp3"
          },
          {
            "num": "27",
            "audioPath": "demo1/bpm75/TND 27_1.mp3"
          },
          {
            "num": "27hi",
            "audioPath": "demo1/bpm75/TND 27hi_1.mp3"
          },
          {
            "num": "27lo",
            "audioPath": "demo1/bpm75/TND 27lo_1.mp3"
          },
          {
            "num": "28",
            "audioPath": "demo1/bpm75/TND 28_1.mp3"
          },
          {
            "num": "28hi",
            "audioPath": "demo1/bpm75/TND 28hi_1.mp3"
          },
          {
            "num": "28lo",
            "audioPath": "demo1/bpm75/TND 28lo_1.mp3"
          },
          {
            "num": "29",
            "audioPath": "demo1/bpm75/TND 29_1.mp3"
          },
          {
            "num": "29hi",
            "audioPath": "demo1/bpm75/TND 29hi_1.mp3"
          },
          {
            "num": "29lo",
            "audioPath": "demo1/bpm75/TND 29lo_1.mp3"
          },
          {
            "num": "30",
            "audioPath": "demo1/bpm75/TND 30_1.mp3"
          },
          {
            "num": "30hi",
            "audioPath": "demo1/bpm75/TND 30hi_1.mp3"
          },
          {
            "num": "30lo",
            "audioPath": "demo1/bpm75/TND 30lo_1.mp3"
          },
          {
            "num": "31",
            "audioPath": "demo1/bpm75/TND 31_1.mp3"
          },
          {
            "num": "31hi",
            "audioPath": "demo1/bpm75/TND 31hi_1.mp3"
          },
          {
            "num": "31lo",
            "audioPath": "demo1/bpm75/TND 31lo_1.mp3"
          },
          {
            "num": "32",
            "audioPath": "demo1/bpm75/TND 32_1.mp3"
          },
          {
            "num": "32hi",
            "audioPath": "demo1/bpm75/TND 32hi_1.mp3"
          },
          {
            "num": "32lo",
            "audioPath": "demo1/bpm75/TND 32lo_1.mp3"
          },
          {
            "num": "33",
            "audioPath": "demo1/bpm75/TND 33_1.mp3"
          },
          {
            "num": "33hi",
            "audioPath": "demo1/bpm75/TND 33hi_1.mp3"
          },
          {
            "num": "33lo",
            "audioPath": "demo1/bpm75/TND 33lo_1.mp3"
          },
          {
            "num": "34",
            "audioPath": "demo1/bpm75/TND 34_1.mp3"
          },
          {
            "num": "34hi",
            "audioPath": "demo1/bpm75/TND 34hi_1.mp3"
          },
          {
            "num": "34lo",
            "audioPath": "demo1/bpm75/TND 34lo_1.mp3"
          },
          {
            "num": "35",
            "audioPath": "demo1/bpm75/TND 35_1.mp3"
          },
          {
            "num": "35hi",
            "audioPath": "demo1/bpm75/TND 35hi_1.mp3"
          },
          {
            "num": "35lo",
            "audioPath": "demo1/bpm75/TND 35lo_1.mp3"
          },
          {
            "num": "36",
            "audioPath": "demo1/bpm75/TND 36 -- brass_1.mp3"
          },
          {
            "num": "36hi",
            "audioPath": "demo1/bpm75/TND 36hi_1.mp3"
          },
          {
            "num": "36lo",
            "audioPath": "demo1/bpm75/TND 36lo_1.mp3"
          },
          {
            "num": "37",
            "audioPath": "demo1/bpm75/TND 37_1.mp3"
          },
          {
            "num": "37hi",
            "audioPath": "demo1/bpm75/TND 37hi_1.mp3"
          },
          {
            "num": "37lo",
            "audioPath": "demo1/bpm75/TND 37lo_1.mp3"
          },
          {
            "num": "38",
            "audioPath": "demo1/bpm75/TND 38_1.mp3"
          },
          {
            "num": "38hi",
            "audioPath": "demo1/bpm75/TND 38hi_1.mp3"
          },
          {
            "num": "38lo",
            "audioPath": "demo1/bpm75/TND 38lo_1.mp3"
          },
          {
            "num": "39",
            "audioPath": "demo1/bpm75/TND 39_1.mp3"
          },
          {
            "num": "39hi",
            "audioPath": "demo1/bpm75/TND 39hi_1.mp3"
          },
          {
            "num": "39lo",
            "audioPath": "demo1/bpm75/TND 39lo_1.mp3"
          },
          {
            "num": "40",
            "audioPath": "demo1/bpm75/TND 40_1.mp3"
          },
          {
            "num": "40hi",
            "audioPath": "demo1/bpm75/TND 40hi_1.mp3"
          },
          {
            "num": "40lo",
            "audioPath": "demo1/bpm75/TND 40lo_1.mp3"
          },
          {
            "num": "41",
            "audioPath": "demo1/bpm75/TND 41_1.mp3"
          },
          {
            "num": "41hi",
            "audioPath": "demo1/bpm75/TND 41hi_1.mp3"
          },
          {
            "num": "41lo",
            "audioPath": "demo1/bpm75/TND 41lo_1.mp3"
          },
          {
            "num": "42",
            "audioPath": "demo1/bpm75/TND 42_1.mp3"
          },
          {
            "num": "42hi",
            "audioPath": "demo1/bpm75/TND 42hi_1.mp3"
          },
          {
            "num": "42lo",
            "audioPath": "demo1/bpm75/TND 42lo_1.mp3"
          },
          {
            "num": "43",
            "audioPath": "demo1/bpm75/TND 43_1.mp3"
          },
          {
            "num": "43hi",
            "audioPath": "demo1/bpm75/TND 43hi_1.mp3"
          },
          {
            "num": "43lo",
            "audioPath": "demo1/bpm75/TND 43lo_1.mp3"
          },
          {
            "num": "44",
            "audioPath": "demo1/bpm75/TND 44_1.mp3"
          },
          {
            "num": "44hi",
            "audioPath": "demo1/bpm75/TND 44hi_1.mp3"
          },
          {
            "num": "44lo",
            "audioPath": "demo1/bpm75/TND 44lo_1.mp3"
          },
          {
            "num": "45",
            "audioPath": "demo1/bpm75/TND 45_1.mp3"
          },
          {
            "num": "45hi",
            "audioPath": "demo1/bpm75/TND 45hi_1.mp3"
          },
          {
            "num": "45lo",
            "audioPath": "demo1/bpm75/TND 45lo_1.mp3"
          },
          {
            "num": "46",
            "audioPath": "demo1/bpm75/TND 46_1.mp3"
          },
          {
            "num": "46hi",
            "audioPath": "demo1/bpm75/TND 46hi_1.mp3"
          },
          {
            "num": "46lo",
            "audioPath": "demo1/bpm75/TND 46lo_1.mp3"
          },
          {
            "num": "47",
            "audioPath": "demo1/bpm75/TND 47_1.mp3"
          },
          {
            "num": "47hi",
            "audioPath": "demo1/bpm75/TND 47hi_1.mp3"
          },
          {
            "num": "47lo",
            "audioPath": "demo1/bpm75/TND 47lo_1.mp3"
          },
          {
            "num": "48",
            "audioPath": "demo1/bpm75/TND 48_1.mp3"
          },
          {
            "num": "48hi",
            "audioPath": "demo1/bpm75/TND 48hi_1.mp3"
          },
          {
            "num": "48lo",
            "audioPath": "demo1/bpm75/TND 48lo_1.mp3"
          },
          {
            "num": "49",
            "audioPath": "demo1/bpm75/TND 49_1.mp3"
          },
          {
            "num": "49hi",
            "audioPath": "demo1/bpm75/TND 49hi_1.mp3"
          },
          {
            "num": "49lo",
            "audioPath": "demo1/bpm75/TND 49lo_1.mp3"
          },
          {
            "num": "50",
            "audioPath": "demo1/bpm75/TND 50_1.mp3"
          },
          {
            "num": "50hi",
            "audioPath": "demo1/bpm75/TND 50hi_1.mp3"
          },
          {
            "num": "50lo",
            "audioPath": "demo1/bpm75/TND 50lo_1.mp3"
          },
          {
            "num": "51",
            "audioPath": "demo1/bpm75/TND 51_1.mp3"
          },
          {
            "num": "51hi",
            "audioPath": "demo1/bpm75/TND 51hi_1.mp3"
          },
          {
            "num": "51lo",
            "audioPath": "demo1/bpm75/TND 51lo_1.mp3"
          },
          {
            "num": "52",
            "audioPath": "demo1/bpm75/TND 52_1.mp3"
          }
        ]
      }
    }
  }
}