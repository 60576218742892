import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './theme/theme';
import { BrowserRouter } from "react-router-dom";
import { MediaContextProvider } from './MediaContextProvider';
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { SStore } from './anyonic/dsl/createStorePs$';
import { appEnv } from './app/env/appEnv';
import { createUI$ } from './app/ui/$UI';


const createUI = (store:SStore<any>) => {

}





const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
  
  <StyledEngineProvider injectFirst>

      <ThemeProvider theme={theme}> 
         <BrowserRouter>
            <MediaContextProvider env={appEnv} ps$={{createUI$}}>
               <App/>
            </MediaContextProvider>
          </BrowserRouter>
       </ThemeProvider>
    </StyledEngineProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
