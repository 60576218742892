import React from 'react';

import './App.css';
import { useEvoPs } from './evoDemos/useEvos';
import { MenuAppBar } from './evoDemos/view/AppBar';
import { EvoView } from './evoDemos/view/EvoView';


//    <MenuAppBar $={$} pm ={pm}/>
function App() {
  const {$,pm} = useEvoPs()
  return (
    <div className="App">
  
      <EvoView $={$} pm ={pm}/>

    </div>
  );
}

export default App;
