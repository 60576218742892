
import { $muEff } from "../../../anyonic/dsl/$muEff"
import { MediaEnv } from "../../page/resource/MediaEnv"
import { Cache, PageCache } from "../../page/resource/PageCache"


import { Page } from "../../page/resource/PageState"
import { PageEff } from "./PageEff"
import { CompositeReducerUpdate } from "../../../anyonic/dsl/dsl-spec"
import { $ } from "../../../anyonic/dsl/mu-types"
import { NavEff } from "../nav/NavEff"


export type $Page = $<PagePM, PageEff, {}, PageRefs>

export type PagePM = {
  page:Page
}

export type PageRefs = {

  cache:PageCache
  loading:any

  
}




export const createPage$ = (env:MediaEnv<any>, $nav:NavEff, update:CompositeReducerUpdate):$Page => {
  
  const {page, cache} = env.resolvePage("/", Cache.EMPTY)

  return $muEff({
    pm: {
      page
    }, 
    refs: {
      cache,
      loading:{}
    },
    mu:{},

    $: ($:$Page) =>  PageEff($, env, $nav),

    update
  })

}





    


