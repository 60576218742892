import { CompositeReducerUpdate } from "../../anyonic/dsl/dsl-spec"
import { $ , $muEff} from "../../anyonic/dsl"


import { PresenceEff } from "./PresenceEff"
import { PresencePM, presence0 } from "./PresencePM"
import { PresencePsEff } from "./PresencePsEff"



export type $Presence = $<
    {
      presence: PresencePM,
    },
    {
      presence:PresenceEff,
      pr:PresencePsEff
    },
    {},
    {}
  >

/** 
 * Process that monitors firebase presence api
 *  
 *  https://firebase.google.com/docs/database/web/offline-capabilities#section-presence
 *   
 */
export const createPresence$ = (update:CompositeReducerUpdate):$Presence => $muEff({
  pm: {
    presence:presence0,  //  :: PresencePM
  },
  mu:{},
  refs:{},
  $: ($:$Presence) => ({
    presence:PresenceEff($),
    pr:PresencePsEff($)
  }),
  update

})

 


