import { FBUser } from "../../model/page/user/FBUser"
import { OFFLINE, ONLINE, presence0, UserPresence } from "./PresencePM"
import { $Presence } from "./$Presence"


 
 export type PresenceEff = {

 

   SetConnected: (connected:boolean) => void
   SetUsers : (users:{[uid:string]:FBUser}) => void
   SetUserConnection: (uid:string, connected:boolean) => void
   Clear: () => void
 } 




  
 
   
export const PresenceEff = ($:$Presence):PresenceEff => {


  return  {

    SetConnected: connected => $.mu.presence({connected}),
    
    SetUsers : usersById => $.mu.presence({ usersById}),


    SetUserConnection: (uid:string, connected:boolean) => (mu, presence) => {

      const status = connected ? ONLINE : OFFLINE
      mu.Append({   
          presenceById:{ [uid]: status}
        })  

      var found = false 

      const userPresences = presence.userPresences.map( up => {
          
        if (!up || !up.user) {
            console.log("prescence issu ?? ")
            console.log(up)
            console.error("presence issue")

          } 
          if (uid === up?.user?.uid) {
            found = true
            if (up.status !== status) { 
              return UserPresence(up.user, status)
            }
          }
          return up
        })

      if (!found) {
        userPresences.push(UserPresence(presence.usersById[uid], status))
      }

      mu({userPresences})
    },

    Clear: () => mu => mu.pm.Put(presence0),




  }


} 


