import { fbPs } from "../../firebase/fbPs"
import { FBUser } from "../../model/page/user/FBUser"
import { $Presence } from "./$Presence"

var HACK_PRESENCE_ENABLED = false


export type PresencePsEff = {
  start: (user?:FBUser) => void
}




export const PresencePsEff = ($:$Presence):PresencePsEff => ({

  /**
   * Manage presence api
   *   
   *  - Query server for current list of users
   *  - Query connection status and update a <presence| table
   *  - Query s
   * 
   */
  start:(user?:FBUser) => $.ps(_ => {

    const {presence:pm} = $.mu() 
    
    // -- no user
    if (!user) {  // <-- require login
      $.presence.Clear()   // <-- delete existing model  
      // -- Q: stop listeners here?
      return
    }
    
    
    // -- 
    if (user !== pm.user) {
      $.presence.Clear()
      $.mu.presence({user})
    }



    const currentUserUid = user.uid

    const _ps = fbPs() // <-- could inject this


    if (!HACK_PRESENCE_ENABLED) {
      HACK_PRESENCE_ENABLED = true
      // -- 1.  
      // -- bug: probably this
      _ps['<users|'].once('value', (snap) => {
        var users = {}
        snap.forEach((childSnapshot) => {
          var k = childSnapshot.key;
          var o = childSnapshot.val();
          users[k] = FBUser(k, o.name, o.avatar)
        });
        $.presence.SetUsers(users)
      });

      

      // -- 1. update presence via connection

      // Create a reference to the special '.info/connected' path in 
      // Realtime Database. This path returns `true` when connected
      // and `false` when disconnected.
      _ps['<connected|'].on('value', snap =>  {
        // If we're not currently connected, don't do anything.
        const connected = snap.val()
        
        if (!connected) {
          $.presence.SetConnected(false)
          return
        } else {
          //console.log('connected ... but wait until ondisconnect is in place to make it official')
        }

        const me = _ps['<presence|'].child(currentUserUid)
        
        me.onDisconnect().remove().then(() => {
            // -- 2. only publish presence once the server has acknowledged the disconnect state
            me.set(true);
            $.presence.SetConnected(true) 
          });
      })




    // -- 3. listen for new users (other than self)

      // FIX_THIS - this is more appropriate to a frequently changing list of anonmyous users
    /* _ps['<users|'].on("child_added", snap => {
        if (currentUserUid !== snap.key) {
          let v = snap.val();
          const user = UserPresence(FBUser(snap.key, v.name, v.avatar), Offline)
          $.mu.AddUserPresence(user)
        }
      })*/

      


      
      // -- 4. listen for added and removed 
      /*
      _ps['<presence|'].on("value", snap => {
        const status = snap.val()
        const uid = snap.key
        if (currentUserUid !== uid) {
          $.mu.AddStatusToUser(uid, status);
        }
      })
    */



      _ps['<presence|'].on("child_added", snap => {
        $.presence.SetUserConnection(snap.key, true);
      })



      _ps['<presence|'].on("child_removed", snap => {
        $.presence.SetUserConnection(snap.key, false);
      })


      $.mu.presence({ user} )
    }

    // -- cleanup
    return () => {
      _ps['<users|'].off()
      _ps['<presence|'].off()
      _ps['<connected|'].off()
      HACK_PRESENCE_ENABLED = false
    }
    

  }) // start
})



